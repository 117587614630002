import React from "react";
import "./../../css/NftMarketplace.css";

export default function MarketplaceHero({ handleComingSoon }) {
  return (
    <div className="flex pt-32 md:p-0 -mt-24 md:flex-row  items-center flex-col justify-between md:-mt-24">
      <div
        className="flex flex-col p-4 md:py-14 md:ml-14 md:mt-24"
        id="marketplace-hero-section-1"
      >
        <div
          className="flex flex-col text-2xl md:text-5xl"
          id="marketplace-hero-text"
        >
          <span>Collect and Trade</span>
          <span>Tokenized Magic Moments.</span>
        </div>
        <div className="text-sm pt-1 md:pt-5 text-neutral-500 md:text-xl">
          Mint, Explore, Collect & Trade
          <br className="md:hidden" /> Magic Moments On Solana.
        </div>
        <div className="flex flex-row justify-between py-4">
          <button
            onClick={() => handleComingSoon()}
            className="py-1 md:py-3 text-base font-bold flex items-center justify-center"
            id="explore-button"
          >
            Explore
          </button>
          <button
            onClick={() => handleComingSoon()}
            className="py-1 md:py-3 text-base font-bold flex items-center justify-center"
            id="create-button"
          >
            Create
          </button>
        </div>
      </div>
      <div
        className="flex items-center p-4 md:pt-20 justify-center"
        id="marketplace-hero-section-2"
      >
        <img
          src="../../assets/images/marketplace-hero.png"
          id="marketplace-hero-img"
          alt=""
        />
      </div>
    </div>
  );
}
