import React from "react";
import NFTCard from "./NFTCard";
import NFTCollectionCard from "./NFTCollectionCard";
import TopCollectionCard from "./TopCollectionCard";
import LatestNFTCard from "./LatestNFTCard";
import nft1 from "../../nfts/nft-1.png";
import nft2 from "../../nfts/nft-2.png";
import nft3 from "../../nfts/nft-3.png";
import nft4 from "../../nfts/nft-4.png";
import nftCover from "../../nfts/nft-collection-bg.png";
import nftAvatar from "../../nfts/nft-collection-profile.png";
import topCollection1 from "../../nfts/top-collection-1.png";
import topCollection2 from "../../nfts/top-collection-2.png";
import topCollection3 from "../../nfts/top-collection-3.png";
import topCollection4 from "../../nfts/top-collection-4.png";
import topCollection5 from "../../nfts/top-collection-5.png";
import topCollection6 from "../../nfts/top-collection-6.png";
import topCollection7 from "../../nfts/top-collection-7.png";
import topCollection8 from "../../nfts/top-collection-8.png";
import latestNft1 from "../../nfts/nft4.png";
import latestNft2 from "../../nfts/nft5.png";
import latestNft3 from "../../nfts/nft6.png";
import supportImg from "../../nfts/support.svg";
import advertImg from "../../nfts/advertising.svg";
import customImg from "../../nfts/custom.svg";

const nfts = [
  {
    id: 1,
    name: "Jues Fles",
    description:
      "Blipmaps are a collection of 444 randomly generated NFTs on the Blockchain.",
    image: nft1,
  },
  {
    id: 2,
    name: "Jues Fles",
    description:
      "Blipmaps are a collection of 444 randomly generated NFTs on the Blockchain.",
    image: nft2,
  },
  {
    id: 3,
    name: "Jues Fles",
    description:
      "Blipmaps are a collection of 444 randomly generated NFTs on the Blockchain.",
    image: nft3,
  },
  {
    id: 4,
    name: "Jues Fles",
    description:
      "Blipmaps are a collection of 444 randomly generated NFTs on the Blockchain.",
    image: nft4,
  },
];

const nftCollection = [
  {
    id: 1,
    name: "Jues Fles",
    description:
      "Blipmaps are a collection of 444 randomly generated NFTs on the Blockchain.",
    avatar: nftAvatar,
    cover: nftCover,
    floor: "220 SOL",
    average: "220 SOL",
  },
  {
    id: 1,
    name: "Jues Fles",
    description:
      "Blipmaps are a collection of 444 randomly generated NFTs on the Blockchain.",
    avatar: nftAvatar,
    cover: nftCover,
    floor: "220 SOL",
    average: "220 SOL",
  },
  {
    id: 1,
    name: "Jues Fles",
    description:
      "Blipmaps are a collection of 444 randomly generated NFTs on the Blockchain.",
    avatar: nftAvatar,
    cover: nftCover,
    floor: "220 SOL",
    average: "220 SOL",
  },
];

const topCollection = [
  {
    id: 1,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection1,
  },
  {
    id: 2,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection2,
  },
  {
    id: 3,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection3,
  },
  {
    id: 4,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection4,
  },
  {
    id: 5,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection5,
  },
  {
    id: 6,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection6,
  },
  {
    id: 7,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection7,
  },
  {
    id: 8,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection8,
  },
  {
    id: 9,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection2,
  },
  {
    id: 10,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection6,
  },
  {
    id: 11,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection1,
  },
  {
    id: 12,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection8,
  },
  {
    id: 13,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection3,
  },
  {
    id: 14,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection7,
  },
  {
    id: 15,
    name: "Antisocial Ape Club: YKTV",
    profit: "+20%",
    floor: "960.62",
    avatar: topCollection6,
  },
];

const latestNFTs = [
  {
    id: 1,
    image: latestNft1,
    name: "Name Of NFT",
    collection: "Collection Name",
    price: "324",
  },
  {
    id: 2,
    image: latestNft2,
    name: "Name Of NFT",
    collection: "Collection Name",
    price: "324",
  },
  {
    id: 3,
    image: latestNft3,
    name: "Name Of NFT",
    collection: "Collection Name",
    price: "324",
  },
];

const benefits = [
  {
    id: 1,
    name: "Advertising",
    image: advertImg,
    content:
      "With your email we create a Remote Digital Wallet to store your NFTs, and give you access on all devices",
  },
  {
    id: 2,
    name: "Custom Website",
    image: customImg,
    content:
      "With your email we create a Remote Digital Wallet to store your NFTs, and give you access on all devices",
  },
  {
    id: 3,
    name: "Pro Support",
    image: supportImg,
    content:
      "With your email we create a Remote Digital Wallet to store your NFTs, and give you access on all devices",
  },
];

export default function NFTLaunch({ handleComingSoon }) {
  return (
    <div className="flex flex-col md:px-6">
      <div className="flex flex-row gap-3 md:gap-6 justify-center items-center p-4 md:w-5/12">
        <img src="../../assets/images/pink-star.png" alt="" />
        <span className="text-2xl md:text-3xl" id="nft-launch-headline">
          Upcoming Launches
        </span>
        <img src="../../assets/images/pink-star.png" alt="" />
      </div>
      <div className="flex flex-wrap gap-4 justify-center py-4">
        {nfts.map((nft) => (
          <NFTCard
            name={nft.name}
            description={nft.description}
            image={nft.image}
            key={nft.id}
          />
        ))}
      </div>
      <div className="flex flex-row gap-2 md:gap-6 justify-center items-center p-4 md:w-5/12">
        <img src="../../assets/images/yellow-star.png" alt="" />
        <span className="text-2xl md:text-3xl" id="nft-launch-headline">
          Featured Collection
        </span>
        <img src="../../assets/images/yellow-star.png" alt="" />
      </div>
      <div className="flex flex-wrap gap-5 justify-center py-4">
        {nftCollection.map((nft) => (
          <NFTCollectionCard
            name={nft.name}
            description={nft.description}
            avatar={nft.avatar}
            cover={nft.cover}
            floor={nft.floor}
            average={nft.average}
            key={nft.id}
          />
        ))}
      </div>
      <div className="flex flex-col md:flex-row p-4 items-center">
        <div className="flex flex-row gap-3 md:gap-6 justify-center items-center p-4 ">
          <img src="../../assets/images/red-star.png" alt="" />
          <span className="text-2xl md:text-3xl" id="nft-launch-headline">
            Top Collection
          </span>
          <img src="../../assets/images/red-star.png" alt="" />
        </div>
        <div className="flex items-center justify-center border border-neutral-500 text-neutral-500 rounded-3xl px-6 my-4 md:my-0 py-2 flex-row gap-2">
          <span className="font-medium text-xl">latest updates</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6 9L12 15L18 9"
              stroke="#798694"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="flex md:hidden flex-wrap gap-5 justify-center py-4">
        {topCollection.slice(0, 5).map((collection) => (
          <TopCollectionCard
            name={collection.name}
            avatar={collection.avatar}
            floor={collection.floor}
            profit={collection.profit}
            key={collection.id}
            id={collection.id}
          />
        ))}
      </div>
      <div className="hidden md:flex flex-wrap gap-5 justify-center py-4">
        {topCollection.slice(0, 15).map((collection) => (
          <TopCollectionCard
            name={collection.name}
            avatar={collection.avatar}
            floor={collection.floor}
            profit={collection.profit}
            key={collection.id}
            id={collection.id}
          />
        ))}
      </div>
      <div className="flex justify-center">
        <button
          onClick={() => handleComingSoon()}
          className="p-2 rounded-3xl md:w-80 w-1/2 text-white font-semibold"
          style={{ border: "2px solid var(--Solid-Yellow, #FFB803)" }}
        >
          Explore Collection
        </button>
      </div>
      <div
        className="flex flex-col my-8 md:my-14 justify-center items-center relative pb-16"
        id="lampapuy-solana"
      >
        <div id="lampapuy-solana-text">Lampapuy is now on Solana</div>
        <div className="flex flex-col justify-center items-center absolute top-4 md:top-14">
          <div id="lampapuy-solana-subtext">Lampapuy is now on Solana</div>
          <div className="text-center md:text-2xl text-xs text-neutral-400">
            Earn more sales at lampapuy.com by owning NFT. <br /> Each Lampa
            will reduce the service fee by 2%
          </div>
          <button
            onClick={() => handleComingSoon()}
            className="font-semibold mt-2 text-lg px-4 py-2"
            id="lampapuy-explore-button"
          >
            Explore Now
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center md:flex-row p-4 mt-4">
        <div className="flex flex-row md:gap-6 w-full md:w-fit justify-center items-center p-2">
          <img src="../../assets/images/green-star.svg" alt="" />
          <span className="text-2xl md:text-3xl" id="nft-launch-headline">
            discover the latest
          </span>
          <img src="../../assets/images/green-star.svg" alt="" />
        </div>
        <div className="flex items-center w-fit justify-center border border-neutral-500 text-neutral-500 rounded-3xl px-6 my-4 md:my-0 py-2 flex-row gap-2">
          <span className="font-medium text-xl">latest updates</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6 9L12 15L18 9"
              stroke="#798694"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="flex overflow-x-auto md:justify-center w-full flex-nowrap gap-4 md:flex-wrap p-4">
        {latestNFTs.map((nft) => (
          <LatestNFTCard
            name={nft.name}
            key={nft.id}
            price={nft.price}
            collection={nft.collection}
            image={nft.image}
          />
        ))}
      </div>
      <div className="flex justify-center">
        <button
          onClick={() => handleComingSoon()}
          className="p-2 my-2 rounded-3xl md:w-80 w-1/2 text-white font-semibold"
          style={{ border: "2px solid var(--Solid-Yellow, #FFB803)" }}
        >
          Explore Collection
        </button>
      </div>
      <div
        className="hidden md:flex px-12 mt-16 pt-20 flex-col"
        id="get-domain"
        style={{ paddingBottom: "6.8rem" }}
      >
        <div
          className="text-3xl"
          id="nft-launch-headline"
          style={{ width: "458px" }}
        >
          Get Started <br /> Pick a domain name
        </div>
        <input
          type="text"
          className="p-4 text-neutral-400 rounded-md my-4 text-base"
          placeholder="Search Domain Name"
          name="domain"
          id=""
          style={{
            backgroundColor: "#242424",
            border: "1px solid #000",
            width: "380px",
          }}
        />
      </div>
      <div
        className="flex flex-col md:hidden p-8 m-3 text-black"
        style={{ height: "656px" }}
        id="mobile-get-domain"
      >
        <div className="text-2xl" id="nft-launch-headline">
          Get Started <br /> Pick a domain name
        </div>
        <input
          type="text"
          className="p-3 text-neutral-400 rounded-md my-4 text-base"
          style={{ backgroundColor: "#242424", border: "1px solid #000" }}
          placeholder="Search Domain Name"
          name="domain"
          id=""
        />
      </div>
      <div className="flex flex-col justify-center py-10">
        <div className="flex flex-row md:gap-6 justify-center items-center p-4 md:w-5/12">
          <img src="../../assets/images/green-star.svg" alt="" />
          <span className="text-2xl md:text-3xl" id="nft-launch-headline">
            How we help creators
          </span>
          <img src="../../assets/images/green-star.svg" alt="" />
        </div>
        <div className="flex flex-wrap gap-5 md:gap-52 p-5 items-center justify-center self-center">
          {benefits.map((benefit) => (
            <div
              key={benefit.id}
              className="flex flex-col"
              id="nft-benefit-card"
            >
              <img src={benefit.image} className="w-20" alt="benefit" />
              <div className="text-2xl md:text-3xl" id="nft-launch-headline">
                {benefit.name}
              </div>
              <div className="text-lg text-neutral-400">{benefit.content}</div>
            </div>
          ))}
          <button
            onClick={() => handleComingSoon()}
            className="flex flex-col p-10 text-black"
            id="nft-plan-card"
          >
            <img
              className="w-20"
              src="../../assets/images/plan.png"
              alt="benefit"
            />
            <div
              className="text-2xl md:text-3xl text-start"
              id="nft-launch-headline"
            >
              Discover Pro Plan
            </div>
            <div className="text-lg">Simplify your Process</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.99821 15.9993C1.99821 15.7341 2.10357 15.4797 2.29111 15.2922C2.47864 15.1047 2.733 14.9993 2.99821 14.9993H26.5842L20.2902 8.70731C20.1972 8.61433 20.1235 8.50396 20.0732 8.38248C20.0228 8.261 19.9969 8.1308 19.9969 7.99931C19.9969 7.86782 20.0228 7.73762 20.0732 7.61614C20.1235 7.49466 20.1972 7.38429 20.2902 7.29131C20.3832 7.19833 20.4936 7.12458 20.615 7.07426C20.7365 7.02395 20.8667 6.99805 20.9982 6.99805C21.1297 6.99805 21.2599 7.02395 21.3814 7.07426C21.5029 7.12458 21.6132 7.19833 21.7062 7.29131L29.7062 15.2913C29.7993 15.3842 29.8732 15.4946 29.9236 15.616C29.9741 15.7375 30 15.8678 30 15.9993C30 16.1308 29.9741 16.2611 29.9236 16.3826C29.8732 16.5041 29.7993 16.6144 29.7062 16.7073L21.7062 24.7073C21.6132 24.8003 21.5029 24.874 21.3814 24.9244C21.2599 24.9747 21.1297 25.0006 20.9982 25.0006C20.8667 25.0006 20.7365 24.9747 20.615 24.9244C20.4936 24.874 20.3832 24.8003 20.2902 24.7073C20.1972 24.6143 20.1235 24.504 20.0732 24.3825C20.0228 24.261 19.9969 24.1308 19.9969 23.9993C19.9969 23.8678 20.0228 23.7376 20.0732 23.6161C20.1235 23.4947 20.1972 23.3843 20.2902 23.2913L26.5842 16.9993H2.99821C2.733 16.9993 2.47864 16.894 2.29111 16.7064C2.10357 16.5189 1.99821 16.2645 1.99821 15.9993Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
