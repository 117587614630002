import React from "react";
import NftMarketplace from "../components/NftMarketplace";

export default function LampapuyNft() {
  return (
    <div>
      <NftMarketplace />
    </div>
  );
}
