import React from "react";

import Hero from "../components/Hero";

function Homepage() {
  return (
    <div>
      <Hero />
    </div>
  );
}

export default Homepage;
