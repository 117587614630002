import React from "react";

export default function Listing() {
  return (
    <div
      className="flex flex-col md:pt-28 md:pb-12 md:px-24 relative px-4 py-8 justify-center items-center"
      id="listing-details"
    >
      <div
        className="md:top-20 top-2 md:px-4 md:py-2 text-sm p-2 md:text-lg font-black absolute z-10"
        id="lpp-address-header"
      >
        $LPP Contract Address
      </div>
      <div
        className="md:py-4 md:text-2xl font-normal text-sm p-3 md:px-6 rounded-lg"
        id="lpp-address"
      >
        J7dsB6BAoBR6cMeT2Pxt1MtB2t4EJ24pRbT1uxkUBsKP
      </div>
      <div
        className="flex flex-col md:flex-row justify-center gap-4 md:gap-8 items-center pt-6 w-full px-6 md:px-10 text-base md:text-2xl font-semibold"
        id="listing-date"
      >
        <div className="flex flex-row gap-1 items-center">
          <a
            href="https://www.mexc.com/exchange/LPP_USDT"
            className="flex flex-row items-center gap-2 w-fit px-6 py-3 md:px-8 md:py-4"
            target="_blank"
            rel="noreferrer"
            style={{
              background: "#FFB803",
              color: "black",
              borderRadius: "24px",
            }}
          >
            <img
              src="../../assets/images/mexc.png"
              alt="mexc"
              className="w-6"
            ></img>
            <span className="font-bold text-base">Buy on MEXC</span>
          </a>
        </div>
        <div className="flex flex-row gap-1 items-center">
          <a
            href="https://jup.ag/swap/SOL-LPP_J7dsB6BAoBR6cMeT2Pxt1MtB2t4EJ24pRbT1uxkUBsKP"
            className="flex flex-row items-center gap-2 w-fit px-6 py-3 md:px-8 md:py-4"
            target="_blank"
            rel="noreferrer"
            style={{
              background: "#FFB803",
              color: "black",
              borderRadius: "24px",
            }}
          >
            <img
              src="../../assets/images/jupyter.png"
              alt="mexc"
              className="w-6"
            ></img>
            <span className="font-bold text-base">Buy on Jupiter</span>
          </a>
        </div>
      </div>
      <div className="flex flex-col gap-1 text-sm items-center md:gap-2 md:text-2xl pt-5 md:pt-8 listing-text">
        <div
          className="font-bold text-lg md:text-2xl"
          style={{ color: "#FFB803" }}
        >
          $LPP💎 BROKE 4 Records 🚀
        </div>
        <div className="flex flex-col md:flex-row text-base md:gap-3 items-center text-center md:text-start md:text-lg">
          <div className="flex flex-col">
            <span>⭐️ Fastest IDO in PinkSale History   🔥</span>
            <span>⭐️ Fastest IDO in Zenix History  🔥 </span>
          </div>
          <div className="flex flex-col">
            <span>⭐️ Fastest IDO of 2024 🔥</span>
            <span>⭐️ Number 1 trending IDO of Week 🔥</span>
          </div>
        </div>
      </div>
    </div>
  );
}
