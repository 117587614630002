import React from "react";
import LampaNft from "../components/LampaNft";

export default function TenKLampaNft() {
  return (
    <div>
      <LampaNft />
    </div>
  );
}
