import React from "react";
import AboutContent from "../components/AboutContent";

export default function About() {
  return (
    <div>
      <AboutContent />
    </div>
  );
}
