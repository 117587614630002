import React from "react";

function LampaAllocation() {
  return (
    <div
      className="flex relative justify-center items-center mx-4 md:mx-0 md:mt-20 md:pb-7"
      id="lampa-allocation-container"
    >
      <div className="absolute md:z-10 md:left-0 md:-bottom-20 invisible md:visible">
        <img src="../../assets/images/pyramid-7.svg" alt="pyramid" />
      </div>
      <div className="absolute z-30 md:right-8 md:-top-20 invisible md:visible">
        <img src="../../assets/images/pyramid-8.svg" alt="pyramid" />
      </div>
      <div className="absolute z-10 md:-bottom-32 md:right-0 invisible md:visible">
        <img src="../../assets/images/pyramid-9.svg" alt="pyramid" />
      </div>
      <div
        className="flex flex-col justify-center md:flex-row md:px-16 md:py-8 z-20"
        id="lampa-allocation-card"
      >
        <div className="md:w-1/2 flex justify-center items-center md:-mt-10">
          <div className="flex flex-col">
            <div
              className="flex flex-col text-center md:text-left"
              id="lampa-allocation-headline"
            >
              <span id="lampa-allocation-headline-span1">10K Lampa</span>
              <span id="lampa-allocation-headline-span2">Allocation</span>
            </div>
            <div className="md:gap-3">
              <ul
                id="lampa-allocation-text"
                className="flex flex-col  gap-2 mt-5"
              >
                <li className="flex flex-row gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                  >
                    <circle cx="13" cy="13.3618" r="13" fill="#BE400A" />
                  </svg>
                  <span>Sale - 60%</span>
                </li>
                <li className="flex flex-row gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                  >
                    <circle cx="13" cy="13.3618" r="13" fill="#FFB803" />
                  </svg>
                  <span>Airdrop for $LPP stakers - 15%</span>
                </li>
                <li className="flex flex-row gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                  >
                    <circle cx="13" cy="13.3618" r="13" fill="#3B580E" />
                  </svg>
                  <span>Lampapuy DAO - 15%</span>
                </li>
                <li className="flex flex-row gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                  >
                    <circle cx="13" cy="13.3618" r="13" fill="#121212" />
                  </svg>
                  <span>Gradual Airdrop for Supporters - 10%</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="md:w-1/2">
          <img
            src="../../assets/images/lampa-allocation.svg"
            alt="allocation"
          />
        </div>
      </div>
    </div>
  );
}

export default LampaAllocation;
