import React from "react";
import LampaNftHero from "./subComponents.js/LampaNftHero";
import LampaDescription from "./subComponents.js/LampaDescription";
import LampaAirdrop from "./subComponents.js/LampaAirdrop";
import LampaAllocation from "./subComponents.js/LampaAllocation";
import LampaPromo from "./subComponents.js/LampaPromo";
import LampaCarousel from "./subComponents.js/LampaCarousel";

export default function LampaNft() {
  return (
    <div style={{ backgroundColor: "#121212" }}>
      <LampaNftHero />
      <LampaDescription />
      <LampaCarousel />
      <LampaAirdrop />
      <LampaAllocation />
      <LampaPromo />
    </div>
  );
}
