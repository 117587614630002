import React from "react";
import "../../css/AboutCard.css";

export default function AboutCard() {
  return (
    <div class="roadmap" className="flex justify-center">
      <div class="roadmap2">
        <div class="roadmap-text">
          <span>
            <span class="roadmap-text-span1">
              We envision Lampapuy as a community project where we make $LPP
            </span>
            <span class="roadmap-text-span2">
              {" "}
              a Utility and Governance Coin
            </span>
            <span class="roadmap-text-span1">
              . We plan to either build new projects from scratch or acquire
              failed projects and relaunch them as part of the Lampapuy
              Ecosystem. This includes
            </span>
            <span class="roadmap-text-span2">
              {" "}
              DEX, NFT Marketplace, Domain Name Service, Staking, Launchpad,
              Gaming, and more.
            </span>
          </span>
        </div>
        <div class="roadmap-utility">Roadmap &amp; Utility</div>
      </div>
      <img
        class="telegram-cloud-photo"
        src="../../assets/images/telegram-cloud-photo.png"
        alt="cloud-pic"
      />
    </div>
  );
}
