import React from "react";

export default function NFTCollectionCard({
  name,
  cover,
  avatar,
  description,
  floor,
  average,
}) {
  return (
    <div
      className="flex flex-col justify-center items-center relative"
      id="nft-collection-card"
    >
      <div id="collection-card-cover">
        <img src={cover} alt="cover" id="nft-collection-cover" />
      </div>
      <img
        src={avatar}
        className="absolute top-24 md:top-32"
        id="nft-collection-avatar"
        alt=""
      />
      <div className="font-semibold text-xl md:text-2xl flex flex-row justify-center items-center gap-1 md:gap-2 pt-6 md:pt-9">
        <span>{name}</span>
        <img src="../../assets/images/yellow-badge.png" alt="" />
      </div>
      <span
        className="px-5 md:px-6 text-center w-80 md:w-96"
        id="nft-description"
      >
        {description}
      </span>
      <div className="p-5 md:p-6 flex w-full flex-col">
        <div className="flex flex-row justify-between text-neutral-400">
          <span>Floor</span>
          <span>30d Avg Price</span>
        </div>
        <div
          className="flex flex-row justify-between"
          style={{ color: "#D48630" }}
        >
          <span>{floor}</span>
          <span>{average}</span>
        </div>
      </div>
    </div>
  );
}
