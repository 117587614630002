import React from "react";
// import "../../App/css";
import "../../css/Roadmap.css";

export default function Roadmap() {
  return (
    <div className="md:flex justify-center hidden md:visible">
      <div id="roadmap" className="flex justify-center">
        <div id="milestone-01">
          <svg
            id="subtract"
            width="920"
            height="276"
            viewBox="0 0 920 276"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.0337 0.896484H901.862C878.157 37.5978 864.553 80.4088 864.553 126.127C864.553 182.06 884.915 233.641 919.224 275.007H35.0337C15.829 275.007 0.299316 260.771 0.299316 243.257V32.6465C0.299316 15.1319 15.829 0.896484 35.0337 0.896484Z"
              fill="#030819"
              stroke="url(#paint0_linear_388_1873)"
              stroke-width="0.5"
            />
            <defs>
              <linearGradient
                id="paint0_linear_388_1873"
                x1="556.424"
                y1="358.56"
                x2="599.011"
                y2="-47.9443"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.320285" />
                <stop offset="0.521506" stop-color="#FFEA7F" />
                <stop offset="1" stop-color="#AB5706" />
              </linearGradient>
            </defs>
          </svg>
          <div id="ellipse-4"></div>
          <div id="frame-2">
            <div id="frame-text">
              <ul className="list-disc">
                <li>Whitepaper & Roadmap Release</li>
                <li> Website, Social Media Launch</li>
                <li>Partnership & Collaboration</li>
              </ul>
            </div>
            <div id="frame-text">
              <ul className="list-disc">
                <li>Marketing Campaign Launch</li>
                <li>Community Incentives Unveiled</li>
                <li>Public Sale / IDO</li>
              </ul>
            </div>
          </div>
          <div id="q-1-2024">
            <span>
              <span id="q-1-2024-span">Q</span>
              <span id="q-1-2024-span2">1 2024</span>
            </span>
          </div>
          <img
            id="image-10"
            alt="penguine"
            src="../../assets/images/image-1.png"
          />
        </div>
        <div id="milestone-02">
          <svg
            id="subtract2"
            width="920"
            height="275"
            viewBox="0 0 920 275"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.0337 0.25H901.862C878.157 36.9513 864.553 79.7623 864.553 125.48C864.553 181.414 884.915 232.995 919.224 274.36H35.0337C15.829 274.36 0.299316 260.125 0.299316 242.61V32C0.299316 14.4855 15.829 0.25 35.0337 0.25Z"
              fill="#030819"
              stroke="url(#paint0_linear_21_189)"
              stroke-width="0.5"
            />
            <defs>
              <linearGradient
                id="paint0_linear_21_189"
                x1="0.0492943"
                y1="-83.649"
                x2="62.281"
                y2="376.464"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.320285" />
                <stop offset="0.521506" stop-color="#FFEA7F" />
                <stop offset="1" stop-color="#AB5706" />
              </linearGradient>
            </defs>
          </svg>
          <div id="ellipse-4"></div>
          <div id="frame-22" className="ml-12 mt-6">
            <div id="frame-text">
              <ul className="list-disc">
                <li>Staking & Governance Unveiled</li>
                <li>NFT ticketing & Crowdvoting Launch</li>
                <li>Crowdfunding Launch</li>
              </ul>
            </div>
            <div id="frame-text">
              <ul className="list-disc">
                <li>NFT Airdrop for Loyal Lampas</li>
                <li>Promotional Fund Launch</li>
                <li>100K Holders Milestone: $1M Giveaways</li>
              </ul>
            </div>
          </div>
          <div id="q-3-2024">
            <span>
              <span id="q-3-2024-span">Q</span>
              <span id="q-3-2024-span2">3 2024</span>
            </span>
          </div>
          <img id="image-11" src="../../assets/images/image-2.png" />
        </div>
        <div id="milestone-03">
          <svg
            id="subtract3"
            width="965"
            height="275"
            viewBox="0 0 965 275"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M927.407 0.25H19.3144C44.1456 36.9505 58.3963 79.7615 58.3963 125.48C58.3963 181.415 37.0654 232.996 1.12616 274.36H927.407C947.542 274.36 963.806 260.115 963.806 242.61V32C963.806 14.4957 947.542 0.25 927.407 0.25Z"
              fill="#030819"
              stroke="url(#paint0_linear_388_1881)"
              stroke-width="0.5"
            />
            <defs>
              <linearGradient
                id="paint0_linear_388_1881"
                x1="724.895"
                y1="-79.0864"
                x2="723.614"
                y2="276.818"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.320285" />
                <stop offset="0.521506" stop-color="#FFEA7F" />
                <stop offset="1" stop-color="#AB5706" />
              </linearGradient>
            </defs>
          </svg>
          <div id="ellipse-42"></div>
          <div id="frame-23" className="mt-20 ml-48">
            <div id="frame-text">
              <ul className="list-disc">
                <li>TGE & Listing</li>
                <li>Stake $LPP: share NFT & Domain sale profits</li>
                <li>.LPP Domain Launch with Bictory.domains</li>
                <li>Lampapuy PFPs Kickoff with Bictory.io</li>
              </ul>
            </div>
            <div id="frame-text">
              <ul className="list-disc">
                <li>PFP NFTs Sale</li>
                <li>$LPP Integration with Other Projects</li>
                <li>Lampapuy DAO Operations Commence</li>
                <li>Community-Initiated Projects Launch</li>
              </ul>
            </div>
          </div>
          <div id="q-2-2024">
            <span>
              <span id="q-2-2024-span">Q</span>
              <span id="q-2-2024-span2">2 2024</span>
            </span>
          </div>
          <img id="image-112" src="../../assets/images/image-2.png" />
        </div>
        <div id="milestone-04">
          <svg
            id="subtract4"
            width="631"
            height="275"
            viewBox="0 0 631 275"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M594.334 0.250122L18.8406 0.25C43.6718 36.9505 57.9225 79.7615 57.9225 125.48C57.9225 181.415 36.5917 232.996 0.652405 274.36L594.334 274.361C614.47 274.361 630.733 260.115 630.733 242.611V32.0001C630.733 14.4959 614.47 0.250122 594.334 0.250122Z"
              fill="#030819"
              stroke="url(#paint0_linear_21_203)"
              stroke-width="0.5"
            />
            <defs>
              <linearGradient
                id="paint0_linear_21_203"
                x1="731.384"
                y1="-95.1975"
                x2="623.525"
                y2="442.723"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.324288" />
                <stop offset="0.751704" stop-color="#FFEA7F" />
                <stop offset="1" stop-color="#AB5706" />
              </linearGradient>
            </defs>
          </svg>
          <div id="ellipse-43"></div>
          <div id="frame-text-2">
            <ul className="list-disc">
              <li>Lampapuy Online & Offline Event</li>
              <li>Partnership Expansion with Organizers & Creators</li>
              <li>Advanced NFT & DeFi Feature Integration</li>
              <li>Global Outreach & Adoption Initiatives</li>
            </ul>
          </div>
          <div id="q-4-2024">
            <span>
              <span id="q-4-2024-span">Q</span>
              <span id="q-4-2024-span2">4 2024</span>
            </span>
          </div>
          <img id="image-113" src="../../assets/images/image-2.png" />
        </div>
        <svg
          id="decor-line"
          width="593"
          height="129"
          viewBox="0 0 593 129"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M592.235 0.847168C470.159 6.7282 409.918 19.6528 299.156 64.6903C195.192 106.963 128.172 129.685 0.561188 128.203"
            stroke="url(#paint0_linear_21_211)"
            stroke-width="0.5"
          />
          <path
            d="M345.564 42.2216C345.674 42.1388 345.83 42.1607 345.914 42.2705L351.171 49.2134C351.29 49.3708 351.188 49.5978 350.991 49.6135L334.785 50.9031C334.535 50.9229 334.415 50.6045 334.615 50.454L345.564 42.2216Z"
            fill="url(#paint1_linear_21_211)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_21_211"
              x1="-6.16118"
              y1="103.995"
              x2="118.312"
              y2="305.624"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFEA7F" />
              <stop offset="1" stop-color="#AB5706" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_21_211"
              x1="332.028"
              y1="51.3899"
              x2="359.736"
              y2="41.8177"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D1993C" />
              <stop offset="1" stop-color="#CF963A" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          id="decor-line2"
          width="581"
          height="99"
          viewBox="0 0 581 99"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.856323 0.719727C122.932 6.60076 132.321 42.4259 251.89 42.4259C353.935 42.4259 453.043 100.358 580.654 98.8761"
            stroke="url(#paint0_linear_21_212)"
            stroke-width="0.5"
          />
          <path
            d="M255.478 42.707C255.713 42.643 255.727 42.3159 255.499 42.2314L239.231 36.2016C239.095 36.151 238.944 36.2273 238.904 36.3674L235.659 47.7268C235.605 47.9142 235.777 48.088 235.965 48.0367L255.478 42.707Z"
            fill="url(#paint1_linear_21_212)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_21_212"
              x1="599.253"
              y1="103.867"
              x2="474.78"
              y2="305.496"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFEA7F" />
              <stop offset="1" stop-color="#AB5706" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_21_212"
              x1="256.178"
              y1="42.6803"
              x2="245.523"
              y2="40.7696"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D1993C" />
              <stop offset="1" stop-color="#CF963A" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          id="decor-line3"
          width="610"
          height="86"
          viewBox="0 0 610 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M609.358 0.768066C487.283 6.6491 404.13 34.526 284.562 34.526C182.517 34.526 127.604 86.7299 -0.00680542 85.2477"
            stroke="url(#paint0_linear_21_217)"
            stroke-width="0.5"
          />
          <path
            d="M274.045 34.957C273.811 34.893 273.796 34.5659 274.024 34.4814L290.292 28.4516C290.429 28.401 290.579 28.4773 290.619 28.6174L293.865 39.9768C293.918 40.1642 293.746 40.338 293.558 40.2867L274.045 34.957Z"
            fill="url(#paint1_linear_21_217)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_21_217"
              x1="10.9622"
              y1="103.916"
              x2="135.435"
              y2="305.545"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFEA7F" />
              <stop offset="1" stop-color="#AB5706" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_21_217"
              x1="273.345"
              y1="34.9303"
              x2="292.376"
              y2="31.6709"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E3BA57" />
              <stop offset="1" stop-color="#E0B351" />
            </linearGradient>
          </defs>
        </svg>
        <div id="group-3">
          <div id="coin">
            <div id="rectangle-1"></div>
            <img id="image-6" src="../../assets/images/image-3.png" />
          </div>
          <div id="coin2">
            <div id="ellipse-12"></div>
            <div id="rectangle-12"></div>
            <img id="image-62" src="../../assets/images/image-4.png" />
          </div>
        </div>
        <div id="roadmap-disclaimer" className="md:flex md:text-center">
          This Roadmap is a preliminary document and is subject to change as{" "}
          <br />
          Lampapuy DAO continues to evolve and adapt to market needs and
          opportunities.
        </div>
      </div>
    </div>
  );
}
