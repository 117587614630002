import React from "react";
import { Spring } from "react-spring/renderprops";
import { withGesture } from "react-with-gesture";

function NavSlide({
  content,
  offsetRadius,
  index,
  animationConfig,
  moveSlide,
  delta,
  down,
  up,
}) {
  const offsetFromMiddle = index - offsetRadius;
  const totalPresentables = 2 * offsetRadius + 1;
  const distanceFactor = 1 - Math.abs(offsetFromMiddle / (offsetRadius + 1));

  const offsetCardClick = (i) => {
    console.log(i);
  };

  const translateYoffset =
    50 * (Math.abs(offsetFromMiddle) / (offsetRadius + 1));
  let translateY = -50;

  if (offsetRadius !== 0) {
    if (index === 0) {
      translateY = 0;
    } else if (index === totalPresentables - 1) {
      translateY = -100;
    }
  }

  if (offsetFromMiddle === 0 && down) {
    translateY += delta[1] / (offsetRadius + 1);
    if (translateY > -40) {
      moveSlide(-1);
    }
    if (translateY < -100) {
      moveSlide(1);
    }
  }
  if (offsetFromMiddle > 0) {
    translateY += translateYoffset;
  } else if (offsetFromMiddle < 0) {
    translateY -= translateYoffset;
  }

  return (
    <Spring
      to={{
        transform: `translateX(0%) translateY(${translateY}%) scale(${distanceFactor})`,
        top: `${
          offsetRadius === 0 ? 50 : 50 + (offsetFromMiddle * 50) / offsetRadius
        }%`,
      }}
      config={animationConfig}
    >
      {(style) => (
        <div
          style={{
            position: "absolute",
            height: "70%",
            top: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transformOrigin: "50% 50%",
            ...style,
            zIndex: Math.abs(Math.abs(offsetFromMiddle) - 2),
          }}
        >
          <div
            style={{
              position: "relative",
              maxWidth: "50%",
              minWidth: "30%",
              width: "20rem",
              height: "8rem",
              background: "#121212",
              fontSize: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transformOrigin: "50% 50%",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => moveSlide(offsetFromMiddle)}
          >
            {content}
          </div>
        </div>
      )}
    </Spring>
  );
}

export default withGesture()(NavSlide);
