import React from "react";
import "../../css/NftMarketplace.css";

export default function ComingSoon() {
  return (
    <div
      className="flex flex-col justify-center items-center rounded-xl p-12 md:p-32 m-6"
      style={{ background: "rgba(0, 0, 0, 0.70)", border: "1px solid #F88430" }}
    >
      <div className="text-base md:text-lg font-bold text-center">
        Lampapuy Exclusive NFT Marketplace on Solana{" "}
      </div>
      <div className="text-4xl md:text-5xl font-semibold" id="coming-soon-text">
        COMING SOON
      </div>
    </div>
  );
}
