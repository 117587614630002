import React from "react";

const Artworks = () => {
  const numArtworks = 12;

  const artworks = Array.from({ length: numArtworks }, (_, i) => (
    <div key={i} id="art-card">
      <img
        className="artwork"
        src={`../assets/images/artwork${i + 1}.png`}
        alt={`artwork${i + 1}`}
      />
    </div>
  ));

  return (
    <div id="art-image-container" className="md:mt-16">
      {artworks}
    </div>
  );
};

export default Artworks;
