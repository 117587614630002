import React from "react";
import "../../css/SfNav.css";

export default function SfNav({ setShowBanner }) {
  const handleCloseBanner = () => {
    setShowBanner(false);
  };
  return (
    <div className="w-full z-20 absolute">
      <div class="noti">
        <div className="md:w-7"></div>
        <div class="frame">
          <div class="text-container">
            <span>
              <span class="text1">
                $LPP💎 listed on MEXC, Orca & Jupiter..{" "}
                <a
                  href="https://t.me/lampapuy"
                  target="_blank"
                  rel="noreferrer"
                  class="text2"
                >
                  Kindly join our Telegram group for updates.
                </a>
              </span>
            </span>
          </div>
        </div>
        <button onClick={handleCloseBanner}>
          <svg
            class="x"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 7L7 17M7 7L17 17"
              stroke="#030819"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
