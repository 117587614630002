import React from "react";
import "../../css/MobileDropdown.css";
import Pdf1 from "../../documents/whitepaper.pdf";
import Pdf2 from "../../documents/pitchDeck.pdf";
import Pdf3 from "../../documents/Onepager.pdf";

export default function MobileDropdown() {
  return (
    <div id="mobile-dropdown">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://linktr.ee/Lampapuy"
        id="linktree-item"
      >
        <svg
          class="linktree"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.5917 6.8775L16.9292 3.44667L18.8667 5.43L15.3667 8.7675H20.29V11.5217H15.3425L18.8667 14.945L16.9292 16.89L12.1458 12.0825L7.36167 16.89L5.42417 14.9525L8.94833 11.5292H4V8.7675H8.92417L5.42417 5.43083L7.36083 3.44667L10.6983 6.8775V2H13.5917V6.8775ZM10.6983 15.4658H13.5917V22H10.6983V15.4658Z"
            fill="white"
          />
        </svg>
        <div id="linktree-label">Linktree</div>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://t.me/lampapuy"
        id="telegram-item"
      >
        <div id="telegram-icon">
          <svg
            class="group"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.7769 4.42997C20.024 4.32596 20.2945 4.29008 20.5602 4.32608C20.8259 4.36208 21.0771 4.46863 21.2876 4.63465C21.4981 4.80067 21.6603 5.02008 21.7573 5.27005C21.8542 5.52002 21.8824 5.79141 21.8389 6.05597L19.5709 19.813C19.3509 21.14 17.8949 21.901 16.6779 21.24C15.6599 20.687 14.1479 19.835 12.7879 18.946C12.1079 18.501 10.0249 17.076 10.2809 16.062C10.5009 15.195 14.0009 11.937 16.0009 9.99997C16.7859 9.23897 16.4279 8.79997 15.5009 9.49997C13.1989 11.238 9.5029 13.881 8.2809 14.625C7.2029 15.281 6.6409 15.393 5.9689 15.281C4.7429 15.077 3.6059 14.761 2.6779 14.376C1.4239 13.856 1.4849 12.132 2.6769 11.63L19.7769 4.42997Z"
              fill="white"
            />
          </svg>
        </div>
        <div id="telegram-label">Telegram</div>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://x.com/LampaCommunity"
        id="x-item"
      >
        <svg
          class="x"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.1761 4H19.9362L13.9061 10.8919L21 20.2703H15.4456L11.0951 14.5824L6.11723 20.2703H3.35544L9.80517 12.8986L3 4H8.69545L12.6279 9.199L17.1761 4ZM16.2073 18.6183H17.7368L7.86441 5.56529H6.2232L16.2073 18.6183Z"
            fill="white"
          />
        </svg>
        <div id="x-label">X</div>
      </a>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://warpcast.com/lampapuy"
        id="x-item"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7.46863 6H3.99121L7.22024 17.1774H10.2009L11.6912 11.9613L13.1815 17.1774H16.1622L19.3912 6H15.9138L14.6719 10.7194L13.1815 6H9.9525L8.71057 10.9677L7.46863 6Z"
            fill="white"
          />
        </svg>
        <div id="x-label">Warpcast</div>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://discord.gg/kucK33WG6k"
        id="discord-item"
      >
        <svg
          class="discord"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.9308 5.26368C17.6561 4.67878 16.2892 4.24785 14.8599 4.00104C14.8339 3.99627 14.8079 4.00818 14.7945 4.03198C14.6187 4.34466 14.4239 4.75258 14.2876 5.0732C12.7503 4.84306 11.221 4.84306 9.71527 5.0732C9.57887 4.74545 9.37707 4.34466 9.20048 4.03198C9.18707 4.00897 9.16107 3.99707 9.13504 4.00104C7.70659 4.24706 6.33963 4.67799 5.06411 5.26368C5.05307 5.26844 5.04361 5.27638 5.03732 5.28669C2.44449 9.16033 1.73421 12.9387 2.08265 16.6703C2.08423 16.6886 2.09447 16.706 2.10867 16.7171C3.81934 17.9734 5.47642 18.7361 7.10273 19.2416C7.12876 19.2496 7.15634 19.24 7.1729 19.2186C7.55761 18.6933 7.90054 18.1393 8.19456 17.5568C8.21192 17.5227 8.19535 17.4822 8.15989 17.4687C7.61594 17.2624 7.098 17.0108 6.59977 16.7251C6.56037 16.7021 6.55721 16.6457 6.59347 16.6187C6.69831 16.5402 6.80318 16.4584 6.9033 16.3759C6.92141 16.3608 6.94665 16.3576 6.96794 16.3671C10.2411 17.8615 13.7846 17.8615 17.0191 16.3671C17.0404 16.3568 17.0657 16.36 17.0846 16.3751C17.1847 16.4576 17.2895 16.5402 17.3952 16.6187C17.4314 16.6457 17.4291 16.7021 17.3897 16.7251C16.8914 17.0163 16.3735 17.2624 15.8288 17.4679C15.7933 17.4814 15.7775 17.5227 15.7949 17.5568C16.0952 18.1385 16.4381 18.6924 16.8157 19.2178C16.8315 19.24 16.8599 19.2496 16.8859 19.2416C18.5201 18.7361 20.1772 17.9734 21.8879 16.7171C21.9028 16.706 21.9123 16.6894 21.9139 16.6711C22.3309 12.357 21.2154 8.60956 18.9568 5.28748C18.9513 5.27638 18.9419 5.26844 18.9308 5.26368ZM8.68335 14.3982C7.69792 14.3982 6.88594 13.4935 6.88594 12.3824C6.88594 11.2713 7.68217 10.3666 8.68335 10.3666C9.69239 10.3666 10.4965 11.2793 10.4807 12.3824C10.4807 13.4935 9.68451 14.3982 8.68335 14.3982ZM15.329 14.3982C14.3435 14.3982 13.5316 13.4935 13.5316 12.3824C13.5316 11.2713 14.3278 10.3666 15.329 10.3666C16.338 10.3666 17.1421 11.2793 17.1264 12.3824C17.1264 13.4935 16.338 14.3982 15.329 14.3982Z"
            fill="white"
          />
        </svg>
        <div id="discord-label">Discord</div>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://medium.com/@lampapuy"
        id="medium-item"
      >
        <svg
          class="medium"
          wth="24"
          height="24"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.5 12.675C22.5 15.3244 22.056 17.4735 21.5081 17.4735C20.9602 17.4735 20.5164 15.325 20.5164 12.675C20.5164 10.025 20.9604 7.87646 21.5081 7.87646C22.0558 7.87646 22.5 10.0248 22.5 12.675Z"
            fill="white"
          />
          <path
            d="M19.9689 12.6748C19.9689 15.6326 18.7062 18.0313 17.1486 18.0313C15.591 18.0313 14.3282 15.6326 14.3282 12.6748C14.3282 9.71705 15.5908 7.31836 17.1484 7.31836C18.706 7.31836 19.9688 9.71629 19.9688 12.6748"
            fill="white"
          />
          <path
            d="M13.7812 12.6751C13.7812 15.8174 11.2558 18.3647 8.14069 18.3647C5.02561 18.3647 2.5 15.8168 2.5 12.6751C2.5 9.53341 5.02541 6.98535 8.14069 6.98535C11.256 6.98535 13.7812 9.53284 13.7812 12.6751Z"
            fill="white"
          />
        </svg>
        <div id="medium-label">Medium</div>
      </a>
      <a href={Pdf1} target="_blank" rel="noreferrer" id="business-paper-item">
        <svg
          class="file-05"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M14 17H8M16 13H8M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div id="business-paper-label">Whitepaper</div>
      </a>
      <a href={Pdf2} target="_blank" rel="noreferrer" id="onepager-item">
        <svg
          class="file-05"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M14 17H8M16 13H8M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div id="onepager-label">Pitch Deck</div>
      </a>
      <a href={Pdf3} target="_blank" rel="noreferrer" id="business-paper-item">
        <svg
          class="file-05"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M14 17H8M16 13H8M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div id="business-paper-label">Lampa NFT</div>
      </a>
      <a
        target="_blank"
        href="mailto:DAO@lampapuy.com"
        rel="noreferrer"
        id="contact-us-item"
      >
        <svg
          class="phone"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.38028 8.85335C9.07627 10.303 10.0251 11.6616 11.2266 12.8632C12.4282 14.0648 13.7869 15.0136 15.2365 15.7096C15.3612 15.7694 15.4235 15.7994 15.5024 15.8224C15.7828 15.9041 16.127 15.8454 16.3644 15.6754C16.4313 15.6275 16.4884 15.5704 16.6027 15.4561C16.9523 15.1064 17.1271 14.9316 17.3029 14.8174C17.9658 14.3864 18.8204 14.3864 19.4833 14.8174C19.6591 14.9316 19.8339 15.1064 20.1835 15.4561L20.3783 15.6509C20.9098 16.1824 21.1755 16.4481 21.3198 16.7335C21.6069 17.301 21.6069 17.9713 21.3198 18.5389C21.1755 18.8242 20.9098 19.09 20.3783 19.6214L20.2207 19.779C19.6911 20.3087 19.4263 20.5735 19.0662 20.7757C18.6667 21.0001 18.0462 21.1615 17.588 21.1601C17.1751 21.1589 16.8928 21.0788 16.3284 20.9186C13.295 20.0576 10.4326 18.4332 8.04466 16.0452C5.65668 13.6572 4.03221 10.7948 3.17124 7.76144C3.01103 7.19699 2.93092 6.91477 2.9297 6.50182C2.92833 6.0436 3.08969 5.42311 3.31411 5.0236C3.51636 4.66357 3.78117 4.39876 4.3108 3.86913L4.46843 3.7115C4.99987 3.18006 5.2656 2.91433 5.55098 2.76999C6.11854 2.48292 6.7888 2.48292 7.35636 2.76999C7.64174 2.91433 7.90747 3.18006 8.43891 3.7115L8.63378 3.90637C8.98338 4.25597 9.15819 4.43078 9.27247 4.60655C9.70347 5.26945 9.70347 6.12403 9.27247 6.78692C9.15819 6.96269 8.98338 7.1375 8.63378 7.4871C8.51947 7.60142 8.46231 7.65857 8.41447 7.72538C8.24446 7.96281 8.18576 8.30707 8.26748 8.58743C8.29048 8.66632 8.32041 8.72866 8.38028 8.85335Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div id="contact-us-label">Contact Us</div>
      </a>
    </div>
  );
}
