import React from "react";
import "../../css/ProfitDistribution.css";

export default function ProfitDistribution() {
  return (
    <div class="distribution">
      <div class="profit-distribution">Profit Distribution</div>
      <div class="profit-row">
        <div class="profit-card">
          <div class="profit-card2">
            <div class="_25">25%</div>
            <div class="profit-disclaimer-text">
              of the profit from these projects
              <br />
              will be used to buy back &amp; burn $LPP
            </div>
          </div>
          <div class="gradient"></div>
        </div>
        <div class="profit-card">
          <div class="profit-card2">
            <div class="_25">50%</div>
            <div class="profit-disclaimer-text">
              will be spent to buy $Sol for Lampapuy DAO
              <br />
              reserve and distribute staking rewards to
              <br />
              $LPP stakers
            </div>
          </div>
          <div class="gradient"></div>
        </div>
        <div class="profit-card">
          <div class="profit-card2">
            <div class="_25">25%</div>
            <div class="profit-disclaimer-text">
              will be allocated for DAO
              <br />
              and development expenses
            </div>
          </div>
          <div class="gradient"></div>
        </div>
      </div>
    </div>
  );
}
