import React from "react";

export default function LatestNFTCard({ name, collection, image, price }) {
  return (
    <div
      className="flex flex-col p-3 md:p-6"
      style={{ backgroundColor: "#0A0A0A" }}
      id="latest-nft-card"
    >
      <div className="flex flex-col text-xs md:text-xl">
        <div className="text-neutral-400">{collection}</div>
        <div className="">{name}</div>
      </div>
      <div className="py-4">
        <img src={image} id="latest-nft-image" alt="nft" />
      </div>
      <div className="flex flex-col">
        <span>Price</span>
        <div className="flex flex-row gap-1">
          <img src="../../assets/images/solana.png" className="w-6" alt="" />
          <span>{price} SOL</span>
        </div>
      </div>
    </div>
  );
}
