import React from "react";
import { Spring } from "react-spring/renderprops";
import { withGesture } from "react-with-gesture";
import nft1 from "../../../../nfts/nft1.png";

function Slide({
  image,
  offsetRadius,
  index,
  animationConfig,
  moveSlide,
  delta,
  down,
  up,
}) {
  const offsetFromMiddle = index - offsetRadius;
  const totalPresentables = 2 * offsetRadius + 1;
  const distanceFactor = 1 - Math.abs(offsetFromMiddle / (offsetRadius + 1));

  const offsetCardClick = (i) => {
    console.log(i);
  };

  const translateYoffset =
    50 * (Math.abs(offsetFromMiddle) / (offsetRadius + 1));
  let translateY = -50;

  if (offsetRadius !== 0) {
    if (index === 0) {
      translateY = 0;
    } else if (index === totalPresentables - 1) {
      translateY = -100;
    }
  }

  if (offsetFromMiddle === 0 && down) {
    translateY += delta[1] / (offsetRadius + 1);
    if (translateY > -40) {
      moveSlide(-1);
    }
    if (translateY < -100) {
      moveSlide(1);
    }
  }
  if (offsetFromMiddle > 0) {
    translateY += translateYoffset;
  } else if (offsetFromMiddle < 0) {
    translateY -= translateYoffset;
  }

  return (
    <Spring
      to={{
        transform: `translateX(0%) translateY(${translateY}%) scale(${distanceFactor})`,
        top: `${
          offsetRadius === 0 ? 50 : 50 + (offsetFromMiddle * 50) / offsetRadius
        }%`,
        filter: `blur(${(1 - distanceFactor) * 10}px)`,
      }}
      config={animationConfig}
    >
      {(style) => (
        <div
          style={{
            position: "absolute",
            height: "70%",
            top: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transformOrigin: "50% 50%",
            ...style,
            zIndex: Math.abs(Math.abs(offsetFromMiddle) - 2),
          }}
        >
          <div
            style={{
              position: "relative",
              maxWidth: "50%",
              minWidth: "30%",
              height: "13rem",
              fontSize: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transformOrigin: "50% 50%",
              color: "black",
            }}
            onClick={() => moveSlide(offsetFromMiddle)}
          >
            <div
              style={{
                width: "30rem",
                borderColor: "#FFBC13",
                border: "0.5px solid #FFBC13",
                borderRadius: "16px",
                padding: "15px",
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.25) 100%)",
                backdropFilter: "blur(2.5px)",
                cursor: "pointer",
              }}
            >
              <img
                src={image}
                style={{
                  width: "28rem",
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      )}
    </Spring>
  );
}

export default withGesture()(Slide);
