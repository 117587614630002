import React from "react";

export default function TopCollectionCard({ id, name, avatar, floor, profit }) {
  return (
    <div
      className="py-2 px-5 flex flex-row gap-2 items-center"
      id="top-collection-card"
    >
      <div>{id}</div>
      <img src={avatar} alt="avatar" className="w-14" />
      <div className="flex flex-col justify-center">
        <div className="flex flex-row justify-between">
          <div className="font-semibold text-base md:text-lg">{name}</div>
          <img src="../../assets/images/verified.svg" alt="" />
        </div>
        <div className="flex flex-row items-center gap-2">
          <span className="text-sm md:text-base text-neutral-400">{floor}</span>
          <img src="../../assets/images/solana.png" className="w-6" alt="" />
          <span className="text-sm md:text-base text-neutral-400">
            Floor Price
          </span>
          <span>{profit}</span>
        </div>
      </div>
    </div>
  );
}
