import React from "react";
import ReactDOM from "react-dom";
import Example from "./components/example";

export default function Slider() {
  return (
    <div className="relative">
      <Example />
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Slider />, rootElement);
