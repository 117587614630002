import React from "react";
import "../../css/DropdownMenu.css";
import "../../App.css";
import Pdf1 from "../../documents/whitepaper.pdf";
import Pdf2 from "../../documents/pitchDeck.pdf";
import Pdf3 from "../../documents/Onepager.pdf";

export default function DropdownMenu() {
  return (
    <div class="option-list absolute" id="dropdown-menu">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://linktr.ee/Lampapuy"
        class="dropdown-item"
        id="dropdown-item"
      >
        <svg
          class="linktree"
          wth="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.0917 7.86285L17.4292 4.43202L19.3667 6.41535L15.8667 9.75285H20.79V12.507H15.8425L19.3667 15.9304L17.4292 17.8754L12.6458 13.0679L7.86167 17.8754L5.92417 15.9379L9.44833 12.5145H4.5V9.75285H9.42417L5.92417 6.41618L7.86083 4.43202L11.1983 7.86285V2.98535H14.0917V7.86285ZM11.1983 16.4512H14.0917V22.9854H11.1983V16.4512Z"
            fill="white"
          />
        </svg>
        <div class="option-item">Linktree</div>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://t.me/lampapuy"
        class="dropdown-item2"
        id="dropdown-item"
      >
        <div class="telegram">
          <svg
            class="group"
            wth="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.277 5.41532C20.5241 5.31131 20.7946 5.27544 21.0603 5.31143C21.326 5.34743 21.5772 5.45399 21.7877 5.62C21.9982 5.78602 22.1604 6.00543 22.2574 6.2554C22.3543 6.50537 22.3825 6.77676 22.339 7.04132L20.071 20.7983C19.851 22.1253 18.395 22.8863 17.178 22.2253C16.16 21.6723 14.648 20.8203 13.288 19.9313C12.608 19.4863 10.525 18.0613 10.781 17.0473C11.001 16.1803 14.501 12.9223 16.501 10.9853C17.286 10.2243 16.928 9.78532 16.001 10.4853C13.699 12.2233 10.003 14.8663 8.78102 15.6103C7.70302 16.2663 7.14102 16.3783 6.46902 16.2663C5.24302 16.0623 4.10602 15.7463 3.17802 15.3613C1.92402 14.8413 1.98502 13.1173 3.17702 12.6153L20.277 5.41532Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="option-item">Telegram</div>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://x.com/LampaCommunity"
        class="dropdown-item2"
        id="dropdown-item"
      >
        <svg
          class="x"
          wth="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.6761 4.98535H20.4362L14.4061 11.8773L21.5 21.2557H15.9456L11.5951 15.5677L6.61723 21.2557H3.85544L10.3052 13.884L3.5 4.98535H9.19545L13.1279 10.1844L17.6761 4.98535ZM16.7073 19.6036H18.2368L8.36441 6.55064H6.7232L16.7073 19.6036Z"
            fill="white"
          />
        </svg>
        <div class="option-item">X</div>
      </a>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://warpcast.com/lampapuy"
        class="dropdown-item2"
        id="dropdown-item"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7.46863 6H3.99121L7.22024 17.1774H10.2009L11.6912 11.9613L13.1815 17.1774H16.1622L19.3912 6H15.9138L14.6719 10.7194L13.1815 6H9.9525L8.71057 10.9677L7.46863 6Z"
            fill="white"
          />
        </svg>
        <div class="option-item">Warpcast</div>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://discord.gg/kucK33WG6k"
        class="dropdown-item2"
        id="dropdown-item"
      >
        <svg
          class="discord"
          wth="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.4308 6.24903C18.1561 5.66413 16.7892 5.2332 15.3599 4.98639C15.3339 4.98162 15.3079 4.99353 15.2945 5.01734C15.1187 5.33002 14.9239 5.73793 14.7876 6.05855C13.2503 5.82841 11.721 5.82841 10.2153 6.05855C10.0789 5.7308 9.87707 5.33002 9.70048 5.01734C9.68707 4.99432 9.66107 4.98242 9.63504 4.98639C8.20659 5.23241 6.83963 5.66334 5.56411 6.24903C5.55307 6.25379 5.54361 6.26173 5.53732 6.27204C2.94449 10.1457 2.23421 13.9241 2.58265 17.6557C2.58423 17.6739 2.59447 17.6914 2.60867 17.7025C4.31934 18.9588 5.97642 19.7215 7.60273 20.227C7.62876 20.2349 7.65634 20.2254 7.6729 20.204C8.05761 19.6786 8.40054 19.1247 8.69456 18.5421C8.71192 18.508 8.69535 18.4675 8.65989 18.454C8.11594 18.2477 7.598 17.9961 7.09977 17.7104C7.06037 17.6874 7.05721 17.6311 7.09347 17.6041C7.19831 17.5255 7.30318 17.4438 7.4033 17.3612C7.42141 17.3462 7.44665 17.343 7.46794 17.3525C10.7411 18.8469 14.2846 18.8469 17.5191 17.3525C17.5404 17.3422 17.5657 17.3454 17.5846 17.3604C17.6847 17.443 17.7895 17.5255 17.8952 17.6041C17.9314 17.6311 17.9291 17.6874 17.8897 17.7104C17.3914 18.0017 16.8735 18.2477 16.3288 18.4533C16.2933 18.4667 16.2775 18.508 16.2949 18.5421C16.5952 19.1238 16.9381 19.6778 17.3157 20.2032C17.3315 20.2254 17.3599 20.2349 17.3859 20.227C19.0201 19.7215 20.6772 18.9588 22.3879 17.7025C22.4028 17.6914 22.4123 17.6747 22.4139 17.6565C22.8309 13.3424 21.7154 9.59492 19.4568 6.27283C19.4513 6.26173 19.4419 6.25379 19.4308 6.24903ZM9.18335 15.3835C8.19792 15.3835 7.38594 14.4788 7.38594 13.3678C7.38594 12.2567 8.18217 11.352 9.18335 11.352C10.1924 11.352 10.9965 12.2646 10.9807 13.3678C10.9807 14.4788 10.1845 15.3835 9.18335 15.3835ZM15.829 15.3835C14.8435 15.3835 14.0316 14.4788 14.0316 13.3678C14.0316 12.2567 14.8278 11.352 15.829 11.352C16.838 11.352 17.6421 12.2646 17.6264 13.3678C17.6264 14.4788 16.838 15.3835 15.829 15.3835Z"
            fill="white"
          />
        </svg>
        <div class="option-item">Discord</div>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://medium.com/@lampapuy"
        class="dropdown-item2"
        id="dropdown-item"
      >
        <svg
          class="medium"
          wth="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.5 12.675C22.5 15.3244 22.056 17.4735 21.5081 17.4735C20.9602 17.4735 20.5164 15.325 20.5164 12.675C20.5164 10.025 20.9604 7.87646 21.5081 7.87646C22.0558 7.87646 22.5 10.0248 22.5 12.675Z"
            fill="white"
          />
          <path
            d="M19.9689 12.6748C19.9689 15.6326 18.7062 18.0313 17.1486 18.0313C15.591 18.0313 14.3282 15.6326 14.3282 12.6748C14.3282 9.71705 15.5908 7.31836 17.1484 7.31836C18.706 7.31836 19.9688 9.71629 19.9688 12.6748"
            fill="white"
          />
          <path
            d="M13.7812 12.6751C13.7812 15.8174 11.2558 18.3647 8.14069 18.3647C5.02561 18.3647 2.5 15.8168 2.5 12.6751C2.5 9.53341 5.02541 6.98535 8.14069 6.98535C11.256 6.98535 13.7812 9.53284 13.7812 12.6751Z"
            fill="white"
          />
        </svg>
        <div class="option-item">Medium</div>
      </a>
      <a
        href={Pdf1}
        target="_blank"
        rel="noreferrer"
        class="dropdown-item2"
        id="dropdown-item"
      >
        <svg
          class="file-05"
          wth="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 3.25488V7.38542C14.5 7.94548 14.5 8.2255 14.609 8.43941C14.7049 8.62758 14.8578 8.78056 15.046 8.87643C15.2599 8.98542 15.5399 8.98542 16.1 8.98542H20.2305M14.5 17.9854H8.5M16.5 13.9854H8.5M20.5 10.9736V18.1854C20.5 19.8655 20.5 20.7056 20.173 21.3473C19.8854 21.9118 19.4265 22.3708 18.862 22.6584C18.2202 22.9854 17.3802 22.9854 15.7 22.9854H9.3C7.61984 22.9854 6.77976 22.9854 6.13803 22.6584C5.57354 22.3708 5.1146 21.9118 4.82698 21.3473C4.5 20.7056 4.5 19.8655 4.5 18.1854V7.78535C4.5 6.10519 4.5 5.26512 4.82698 4.62338C5.1146 4.05889 5.57354 3.59995 6.13803 3.31233C6.77976 2.98535 7.61984 2.98535 9.3 2.98535H12.5118C13.2455 2.98535 13.6124 2.98535 13.9577 3.06824C14.2638 3.14173 14.5564 3.26294 14.8249 3.42743C15.1276 3.61296 15.387 3.87238 15.9059 4.39124L19.0941 7.57946C19.613 8.09832 19.8724 8.35775 20.0579 8.6605C20.2224 8.92891 20.3436 9.22155 20.4171 9.52766C20.5 9.87292 20.5 10.2398 20.5 10.9736Z"
            stroke="white"
            stroke-wth="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="option-item">Whitepaper</div>
      </a>
      <a
        href={Pdf2}
        target="_blank"
        rel="noreferrer"
        class="dropdown-item2"
        id="dropdown-item"
      >
        <svg
          class="file-052"
          wth="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 3.25488V7.38542C14.5 7.94548 14.5 8.2255 14.609 8.43941C14.7049 8.62758 14.8578 8.78056 15.046 8.87643C15.2599 8.98542 15.5399 8.98542 16.1 8.98542H20.2305M14.5 17.9854H8.5M16.5 13.9854H8.5M20.5 10.9736V18.1854C20.5 19.8655 20.5 20.7056 20.173 21.3473C19.8854 21.9118 19.4265 22.3708 18.862 22.6584C18.2202 22.9854 17.3802 22.9854 15.7 22.9854H9.3C7.61984 22.9854 6.77976 22.9854 6.13803 22.6584C5.57354 22.3708 5.1146 21.9118 4.82698 21.3473C4.5 20.7056 4.5 19.8655 4.5 18.1854V7.78535C4.5 6.10519 4.5 5.26512 4.82698 4.62338C5.1146 4.05889 5.57354 3.59995 6.13803 3.31233C6.77976 2.98535 7.61984 2.98535 9.3 2.98535H12.5118C13.2455 2.98535 13.6124 2.98535 13.9577 3.06824C14.2638 3.14173 14.5564 3.26294 14.8249 3.42743C15.1276 3.61296 15.387 3.87238 15.9059 4.39124L19.0941 7.57946C19.613 8.09832 19.8724 8.35775 20.0579 8.6605C20.2224 8.92891 20.3436 9.22155 20.4171 9.52766C20.5 9.87292 20.5 10.2398 20.5 10.9736Z"
            stroke="white"
            stroke-wth="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="option-item">Pitch Deck</div>
      </a>
      <a
        href={Pdf3}
        target="_blank"
        rel="noreferrer"
        class="dropdown-item2"
        id="dropdown-item"
      >
        <svg
          class="file-05"
          wth="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 3.25488V7.38542C14.5 7.94548 14.5 8.2255 14.609 8.43941C14.7049 8.62758 14.8578 8.78056 15.046 8.87643C15.2599 8.98542 15.5399 8.98542 16.1 8.98542H20.2305M14.5 17.9854H8.5M16.5 13.9854H8.5M20.5 10.9736V18.1854C20.5 19.8655 20.5 20.7056 20.173 21.3473C19.8854 21.9118 19.4265 22.3708 18.862 22.6584C18.2202 22.9854 17.3802 22.9854 15.7 22.9854H9.3C7.61984 22.9854 6.77976 22.9854 6.13803 22.6584C5.57354 22.3708 5.1146 21.9118 4.82698 21.3473C4.5 20.7056 4.5 19.8655 4.5 18.1854V7.78535C4.5 6.10519 4.5 5.26512 4.82698 4.62338C5.1146 4.05889 5.57354 3.59995 6.13803 3.31233C6.77976 2.98535 7.61984 2.98535 9.3 2.98535H12.5118C13.2455 2.98535 13.6124 2.98535 13.9577 3.06824C14.2638 3.14173 14.5564 3.26294 14.8249 3.42743C15.1276 3.61296 15.387 3.87238 15.9059 4.39124L19.0941 7.57946C19.613 8.09832 19.8724 8.35775 20.0579 8.6605C20.2224 8.92891 20.3436 9.22155 20.4171 9.52766C20.5 9.87292 20.5 10.2398 20.5 10.9736Z"
            stroke="white"
            stroke-wth="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="option-item">Lampa NFT</div>
      </a>
      <a
        target="_blank"
        href="mailto:DAO@lampapuy.com"
        rel="noreferrer"
        class="dropdown-item3"
        id="dropdown-item"
      >
        <svg
          class="phone"
          wth="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.88028 9.8387C9.57627 11.2883 10.5251 12.647 11.7266 13.8485C12.9282 15.0501 14.2869 15.9989 15.7365 16.6949C15.8612 16.7548 15.9235 16.7847 16.0024 16.8077C16.2828 16.8894 16.627 16.8307 16.8644 16.6607C16.9313 16.6129 16.9884 16.5557 17.1027 16.4414C17.4523 16.0918 17.6271 15.917 17.8029 15.8027C18.4658 15.3717 19.3204 15.3717 19.9833 15.8027C20.1591 15.917 20.3339 16.0918 20.6835 16.4414L20.8783 16.6363C21.4098 17.1677 21.6755 17.4334 21.8198 17.7188C22.1069 18.2864 22.1069 18.9566 21.8198 19.5242C21.6755 19.8096 21.4098 20.0753 20.8783 20.6068L20.7207 20.7644C20.1911 21.294 19.9263 21.5588 19.5662 21.7611C19.1667 21.9855 18.5462 22.1469 18.088 22.1455C17.6751 22.1443 17.3928 22.0642 16.8284 21.9039C13.795 21.043 10.9326 19.4185 8.54466 17.0305C6.15668 14.6425 4.53221 11.7802 3.67124 8.74679C3.51103 8.18234 3.43092 7.90012 3.4297 7.48717C3.42833 7.02895 3.58969 6.40846 3.81411 6.00895C4.01636 5.64892 4.28117 5.38411 4.8108 4.85448L4.96843 4.69685C5.49987 4.16541 5.7656 3.89969 6.05098 3.75534C6.61854 3.46827 7.2888 3.46827 7.85636 3.75534C8.14174 3.89969 8.40747 4.16541 8.93891 4.69685L9.13378 4.89172C9.48338 5.24133 9.65819 5.41613 9.77247 5.59191C10.2035 6.2548 10.2035 7.10938 9.77247 7.77227C9.65819 7.94805 9.48338 8.12285 9.13378 8.47246C9.01947 8.58677 8.96231 8.64392 8.91447 8.71074C8.74446 8.94817 8.68576 9.29242 8.76748 9.57278C8.79048 9.65167 8.82041 9.71401 8.88028 9.8387Z"
            stroke="white"
            stroke-wth="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="option-item">Contact Us</div>
      </a>
    </div>
  );
}
