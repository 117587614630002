import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LPPArt from "./pages/LPPArt";
import About from "./pages/About";
import SfNav from "./components/subComponents.js/SfNav";
import { useState } from "react";
import TenKLampaNft from "./pages/10KLampaNft";
import LampapuyNft from "./pages/LampapuyNft";

function App() {
  const [showBanner, setShowBanner] = useState(true);
  const [color, changeColor] = useState("#030819");

  return (
    <div className="App" style={{ background: color }}>
      <Router>
        <Header changeColor={changeColor} />
        {showBanner && <SfNav setShowBanner={setShowBanner} />}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/lppart" element={<LPPArt />} />
          <Route path="/about" element={<About />} />
          <Route path="/10KLampaNFTs" element={<TenKLampaNft />} />
          <Route path="/LampapuyNFTs" element={<LampapuyNft />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
