import Artworks from "./subComponents.js/Artworks";

export default function ArtContent() {
  return (
    <div>
      <div
        className="flex flex-col items-center relative overflow-hidden pb-9 md:p-0"
        id="art-section1"
      >
        <div id="stars-image-container">
          <img
            id="stars-image"
            src="./assets/images/stars.png"
            className="w-screen"
            alt=""
          />
        </div>
        <div
          className="absolute flex flex-col mt-4 md:mt-14"
          id="art-start-section"
        >
          <div
            id="art-heading"
            className="flex flex-col md:flex-row justify-center"
          >
            <span id="art-heading1">Greetings to&nbsp;</span>
            <span id="art-heading2">the world of Lampapuy</span>
          </div>
          <h2 id="art-subheading">
            a groundbreaking Community Coin reshaping the{" "}
            <br className="visible md:hidden" /> very essence of the crypto
            landscape!
          </h2>
          <div
            id="lpp-art-banner"
            className="flex flex-row mt-20 md:mt-72 relative justify-center items-center z-10"
          >
            <div id="lpp-hero-card1" className="absolute z-10">
              <img
                src="./assets/images/lpp-hero-1.jpg"
                id="lpp-hero-image-small"
                alt=""
              />
            </div>
            <div id="lpp-hero-card2" className="absolute z-20">
              <img
                src="./assets/images/lpp-hero-2.jpg"
                id="lpp-hero-image-small"
                alt=""
              />
            </div>
            <div id="lpp-hero-card3" className="absolute z-30">
              <img
                src="./assets/images/lpp-hero-3.jpg"
                id="lpp-hero-image-small"
                alt=""
              />
            </div>
            <div id="lpp-hero-card4" className="absolute z-40">
              <img
                src="./assets/images/lpp-hero-4.jpg"
                id="lpp-hero-image"
                alt=""
              />
            </div>
            <div id="lpp-hero-card5" className="absolute z-30">
              <img
                src="./assets/images/lpp-hero-5.jpg"
                id="lpp-hero-image-small"
                alt=""
              />
            </div>
            <div id="lpp-hero-card6" className="absolute z-20">
              <img
                src="./assets/images/lpp-hero-6.jpg"
                id="lpp-hero-image-small"
                alt=""
              />
            </div>
            <div id="lpp-hero-card7" className="absolute z-10">
              <img
                src="./assets/images/lpp-hero-7.jpg"
                id="lpp-hero-image-small"
                alt=""
              />
            </div>
          </div>
          <div className="self-center mt-20 md:mt-56" id="ellipse-17"></div>
        </div>
      </div>

      <div id="art-section2" className="flex justify-center mt-12">
        <div id="heading-container" className="flex justify-center flex-col">
          <h1 id="section2-text1" className="self-center">
            Check Out!
          </h1>
          <h1 id="section2-text2">
            our amazing <br className="visible md:hidden" />
            Lampapuy artworks!
          </h1>
        </div>
      </div>
      <div
        id="art-section3"
        className="flex justify-center my-6 mb-8 md:my-0 md:mb-32"
      >
        <Artworks />
      </div>
    </div>
  );
}
