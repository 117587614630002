import React from "react";
import Nft from "../../videos/Nft.mp4";
import "../../css/LampaNft.css";

export default function LampaNftHero() {
  return (
    <div className="flex pt-32 md:p-0 -mt-24 md:flex-row flex-col justify-between md:-mt-24">
      <div
        className="flex items-center p-4 md:p-0 md:ml-14 md:mt-24  md:w-1/2"
        id="lampa-hero-text-container"
      >
        <div className="flex flex-col" id="lampa-hero-text">
          <span id="lampa-hero-text-span1" className="flex flex-row">
            Introducing{" "}
            <span>
              <sup>
                <img
                  src="../../assets/images/pyramid-1.png"
                  alt="pyramid"
                  id="lampa-hero-svg"
                />
              </sup>
            </span>
          </span>
          <span id="lampa-hero-text-span2">The Number 1</span>
          <span id="lampa-hero-text-span3">NFT Collection</span>
          <span id="lampa-hero-text-span4">of 2024</span>
        </div>
      </div>
      <div className="md:w-1/2 md:mt-24" id="video-container">
        <video src={Nft} autoPlay muted loop id="hero-video"></video>
        <div id="video-shadows"></div>
      </div>
    </div>
  );
}
