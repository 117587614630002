import React from "react";
import "../../css/LampaNft.css";

export default function LampaDescription() {
  return (
    <div className="flex justify-center" id="lampa-nft-description">
      <div id="pyramid2" className="absolute md:relative">
        <img src="../../assets/images/pyramid-2.svg" alt="pyramid" />
      </div>
      <div id="pyramid3" className="z-30 absolute">
        <img src="../../assets/images/pyramid-3.svg" alt="pyramid" />
      </div>
      <div id="pyramid4" className="z-30 absolute">
        <img src="../../assets/images/pyramid-4.svg" alt="pyramid" />
      </div>
      <div id="pyramid5" className="z-30 absolute">
        <img src="../../assets/images/pyramid-5.svg" alt="pyramid" />
      </div>
      <div
        className="absolute md:mx-20 mx-4 my-16 flex flex-col md:flex-row"
        id="nft-description-card"
      >
        <div className="md:w-1/2">
          <div>
            <div id="nft-description-container">
              <svg
                id="nft-description-svg-1"
                width="84"
                height="46"
                viewBox="0 0 84 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.515625"
                  y1="-0.8125"
                  x2="83.5156"
                  y2="-0.8125"
                  stroke="#D48630"
                  stroke-width="3"
                />
                <line
                  x1="2.01562"
                  y1="0.6875"
                  x2="2.01562"
                  y2="45.6875"
                  stroke="#D48630"
                  stroke-width="3"
                />
              </svg>
              <svg
                id="nft-description-svg-2"
                width="84"
                height="46"
                viewBox="0 0 84 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="83.2012"
                  y1="47"
                  x2="0.201172"
                  y2="47"
                  stroke="#D48630"
                  stroke-width="3"
                />
                <line
                  x1="81.7012"
                  y1="45.5"
                  x2="81.7012"
                  y2="0.5"
                  stroke="#D48630"
                  stroke-width="3"
                />
              </svg>
              <div id="nft-description-text">Lampa 10K PFP NFT Collection</div>
            </div>
          </div>
          <div
            className="relative px-5 py-5 md:px-14 md:py-32 flex flex-col"
            id="nft-description-text-para"
          >
            <span>
              Lampa 10K NFTs are unique, hand-drawn, and randomly generated
              artworks featuring a mix of ~200 different traits from 10 animals.
              These delightful creatures will be minted on the Solana
              Blockchain, establishing the number one NFT collection. All Lampa
              NFTs are exceptional, with some being rarer than others.
            </span>
            <span className="md:pt-5" id="nft-description-text-para-span2">
              Go figure! We are community-focused, offering exclusive holder
              benefits
            </span>
          </div>
        </div>
        <div className="md:w-1/2 absolute">
          <div id="nft-art-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="84"
              height="46"
              viewBox="0 0 84 46"
              fill="none"
              id="nft-art-svg-1"
            >
              <line
                x1="0.15625"
                y1="0.5"
                x2="83.1562"
                y2="0.5"
                stroke="white"
              />
              <line x1="0.65625" y1="1" x2="0.656248" y2="46" stroke="white" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="84"
              height="46"
              viewBox="0 0 84 46"
              fill="none"
              id="nft-art-svg-2"
            >
              <line
                x1="83.1562"
                y1="45.5"
                x2="0.15625"
                y2="45.5"
                stroke="white"
              />
              <line
                x1="82.6562"
                y1="45"
                x2="82.6562"
                y2="-2.18557e-08"
                stroke="white"
              />
            </svg>
            <img id="nft-art-image" src="../../assets/images/image-68.png" />
          </div>
        </div>
      </div>
    </div>
  );
}
