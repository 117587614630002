import React from "react";
import "../../css/LampaNft.css";

export default function LampaPromo() {
  const nftInfo = [
    { label: "NFT Name", value: "Lampa" },
    { label: "Symbol", value: "LAMPA" },
    { label: "Blockchain", value: "Solana" },
    { label: "NFT Standard", value: "SPL" },
    { label: "Max Supply", value: "10,000 NFTs" },
    { label: "Mint Authority", value: "TBA" },
    { label: "Mint Price", value: "TBA" },
  ];

  return (
    <div className="w-full flex flex-col md:flex-row mt-8" id="lampa-promo">
      <div
        className="md:w-1/2 px-8 md:px-16 flex flex-col justify-center items-center"
        id="lampa-promo-div1"
      >
        <div className="" id="lampa-promo-headline-1">
          Key Metrics
        </div>
        <ul className="flex flex-col w-full gap-4 mt-4 md:px-5">
          {nftInfo.map((info) => (
            <li key={info.label} className="flex justify-between">
              <span id="lampa-promo-info1">{info.label}</span>
              <span id="lampa-promo-info2">{info.value}</span>
            </li>
          ))}
        </ul>
      </div>
      <div
        className="md:w-1/2 flex items-center px-8 md:px-16"
        id="lampa-promo-div2"
      >
        <div className="flex flex-col">
          <div id="lampa-promo-headline-2">
            FOR <br />
            PRIVATE <br id="br-tag" />
            SALE
          </div>
          <div className="py-3" id="lampa-promo-email-at">
            EMAIL AT
          </div>
          <div id="lampa-promo-email">DAO@Lampapuy.com</div>
        </div>
      </div>
    </div>
  );
}
