import React from "react";

export default function NFTCard({ name, description, image }) {
  return (
    <div
      className="flex flex-col p-2 justify-center items-center gap-3 md:p-4 md:gap-4"
      id="nft-launch-card"
    >
      <img src={image} className="md:w-64 w-36 rounded-lg" alt="nft" />
      <span className="text-xs font-semibold md:text-lg">{name}</span>
      <span id="nft-description">{description}</span>
    </div>
  );
}
