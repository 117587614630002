import React from "react";
import "../../css/MobileAboutCard.css";

export default function MobileAboutCard() {
  return (
    <div id="utility-container">
      <div id="utility-section">
        <div id="utility-title">Roadmap &amp; Utility</div>
        <div id="project-description">
          <span id="project-summary">
            <span id="project-summary-part1">
              We envision Lampapuy as a community project where we make $LPP
            </span>
            <span id="project-summary-part2">
              a Utility and Governance Coin
            </span>
            <span id="project-summary-part3">
              . We plan to either build new projects from scratch or acquire
              failed projects and relaunch them as part of the Lampapuy
              Ecosystem. This includes
            </span>
            <span id="project-summary-part4">
              DEX, NFT Marketplace, Domain Name Service, Staking, Launchpad,
              Gaming, and more.
            </span>
          </span>
        </div>
      </div>
      <img
        id="project-image"
        src="../../assets/images/telegram-cloud-photo.png"
        alt="cloud-pic"
      />
    </div>
  );
}
