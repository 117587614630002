import React from "react";
import Slider from "./Slider";

export default function LampaCarousel() {
  return (
    <div className="flex flex-col items-center justify-center overflow-hidden">
      <div className="flex flex-col justify-center items-center">
        <span id="carousel-compnent-headline1">Check out!</span>
        <span id="carousel-compnent-headline2">Lampa NFTs</span>
      </div>
      <div className="-mt-32 md:-mt-20">
        <Slider />
      </div>
    </div>
  );
}
