import React from "react";
import AboutCard from "./subComponents.js/AboutCard";
import ProfitDistribution from "./subComponents.js/ProfitDistribution";
import Announcement from "./subComponents.js/Announcement";
import MobileAboutCard from "./subComponents.js/MobileAboutCard";

export default function AboutContent() {
  return (
    <div className="md:mb-20 relative overflow-hidden">
      <div
        id="about-hero"
        className="flex items-center justify-center md:flex-col relative"
      >
        <h1 id="about-heading">
          A couple of Web3 enthusiasts <br />
          have recently initiated Lampapuy, <br />
          the project for the Real Solana Community.
        </h1>
        <div id="card" className="flex justify-center items-center absolute">
          <div>
            <h1 id="card-text" className="text-center self-center">
              We emphatically prefer not to refer to it as a{" "}
              <span id="color-text">"meme coin"</span> <br />
              but as the <span id="color-text">Solana Community Coin.</span> Our
              focus is on building <br />
              the community first and then developing utility.
            </h1>
          </div>
        </div>
      </div>

      <div
        id="allocation-div"
        className="flex flex-col justify-center md:flex-row pb-52 md:pb-80"
      >
        <div id="allocation-text" className="md:w-2/3">
          <h1 id="allocation-heading">$LPP Coin Allocation</h1>
          <p id="allocation-content">
            <div>
              We will distribute{" "}
              <span id="highlight-text">51% of $LPP Coins</span> through a
              <span id="highlight-text"> fair airdrop</span> to Lampapuy
              Community Members on X, Telegram, and Discord. To be eligible,
              join all three platforms and remain active{" "}
              <a href="https://linktr.ee/Lampapuy" id="link">
                (https://linktr.ee/Lampapuy)
              </a>
              . This airdrop is also open to other Solana Project users.
            </div>{" "}
            <br />
            <div>
              {" "}
              We acknowledge that the last 30 months were marked by a brutal
              bear market, and many of us experienced losses due to crypto
              hacks, frauds, failed projects, pump &{" "}
              <dumps className="span" id="highlight-text">
                {" "}
                Consider $LPP as a payoff for still believing in Solana,
                Bitcoin, Blockchain, and being here.
              </dumps>
            </div>
          </p>
          <div id="allocation-bottom-text" className="flex flex-col">
            <div
              id="allocation-bottom-text-div"
              className="flex flex-col md:flex-row"
            >
              <div className="flex flex-row gap-4 md:gap-6">
                <img
                  id="bottom-image"
                  src="./assets/images/yellow-circle.svg"
                  alt=""
                />
                <span id="bottom-text">Airdrop - 51%</span>
              </div>
              <div className="flex flex-row gap-4 md:gap-6">
                <img
                  id="bottom-image"
                  src="./assets/images/red-circle.svg"
                  alt=""
                />
                <span id="bottom-text">
                  Listing & Liquidity & Marketing - 19%
                </span>
              </div>
            </div>
            <div
              id="allocation-bottom-text-div"
              className="flex flex-col md:flex-row"
            >
              <div className="flex flex-row gap-4 md:gap-6">
                <img
                  id="bottom-image"
                  src="./assets/images/green-circle.svg"
                  alt=""
                />
                <span id="bottom-text">Lampapuy DAO - 20%</span>
              </div>
              <div className="flex flex-row gap-4 md:gap-6">
                <img
                  id="bottom-image"
                  src="./assets/images/slate-circle.svg"
                  alt=""
                />
                <span id="bottom-text">Early Contributors - 5%</span>
              </div>
              <div className="flex flex-row gap-4 md:gap-6">
                <img
                  id="bottom-image"
                  src="./assets/images/beige-circle.svg"
                  alt=""
                />
                <span id="bottom-text">Public sale - 5%</span>
              </div>
            </div>
          </div>
          <div id="allocation-disclaimer-text">
            *Lampapuy DAO will raise initial funding (for development) by
            selling its small portion of allocation. <br />
            *100% unlock and Lampapuy DAO will have the right to manage
            allocation and timely distribution.
          </div>
        </div>
        <div id="allocation-image-div" className="md:w-1/3">
          <img
            src="./assets/images/coin-allocation2.png"
            id="allocation-image"
            alt=""
          />
        </div>
      </div>
      <div
        id="roadmap-div"
        className="md:flex hidden md:visible md:justify-center"
      >
        <AboutCard />
      </div>
      <div className="flex justify-center visible md:hidden">
        <MobileAboutCard />
      </div>
      <div id="bottom-div" className="flex justify-center">
        <div id="profit-distribution" className="md:mb-24 flex justify-center">
          <ProfitDistribution />
        </div>
      </div>
      <div id="announcement" className="flex justify-center">
        <Announcement />
      </div>
    </div>
  );
}
