import React from "react";

export default function LampaAirdrop() {
  return (
    <div className="md:mt-16 flex flex-col justify-center">
      <div className="flex flex-col items-center justify-center">
        <span id="lampa-airdrop-text1">$LPP Coins will be</span>
        <span id="lampa-airdrop-text2">Airdropped to each Lampa holder</span>
      </div>

      <div className="flex md:flex-row flex-col justify-center md:mx-24 md:mt-10 p-4 md:p-0">
        <div className="md:w-1/2 flex flex-col">
          <ul>
            <li
              className="flex flex-row items-center gap-3 py-2"
              id="lampa-airdrop-text"
            >
              <img src="../../assets/images/pyramid-6.svg" alt="pyramid" />
              <div
                className="flex flex-row gap-2 items-center"
                id="lampa-airdrop-point-frame"
              >
                <img
                  src="../../assets/images/line.svg"
                  id="lampa-point-line1"
                  alt="line"
                />
                Exclusive Discounts in <br id="br-tag" />
                Lampapuy Merchandise Store
              </div>
            </li>
            <li
              className="flex flex-row items-center gap-3 py-2"
              id="lampa-airdrop-text"
            >
              <img
                src="../../assets/images/pyramid-6.svg"
                alt="pyramid"
                id="lampa-point-line1"
              />
              <div
                className="flex flex-row gap-2 items-center"
                id="lampa-airdrop-point-frame"
              >
                <img src="../../assets/images/line.svg" alt="line" />
                Exclusive Airdrop of .LPP Domain Names
              </div>
            </li>
            <li
              className="flex flex-row items-center gap-3 py-2"
              id="lampa-airdrop-text"
            >
              <img src="../../assets/images/pyramid-6.svg" alt="pyramid" />
              <div
                className="flex flex-row gap-2 items-center"
                id="lampa-airdrop-point-frame"
              >
                <img
                  src="../../assets/images/line.svg"
                  alt="line"
                  id="lampa-point-line1"
                />
                Early Access to Lampapuy Products
              </div>
            </li>
            <li
              className="flex flex-row items-center gap-3 py-2"
              id="lampa-airdrop-text"
            >
              <img
                src="../../assets/images/pyramid-6.svg"
                alt="pyramid"
                id="lampa-point-line1"
              />
              <div
                className="flex flex-row gap-2 items-center"
                id="lampa-airdrop-point-frame"
              >
                <img src="../../assets/images/line.svg" alt="line" />
                Priority waitlist for Future Lampapuy Projects
              </div>
            </li>
            <li
              className="flex flex-row items-center gap-3 py-2"
              id="lampa-airdrop-text"
            >
              <img src="../../assets/images/pyramid-6.svg" alt="pyramid" />

              <div
                className="flex flex-row gap-2 items-center"
                id="lampa-airdrop-point-frame"
              >
                <img src="../../assets/images/line.svg" alt="line" />
                Exclusive Access to Lampapuy Discord Channel
              </div>
            </li>
            <li
              className="flex flex-row items-center gap-3 py-2"
              id="lampa-airdrop-text"
            >
              <img src="../../assets/images/pyramid-6.svg" alt="pyramid" />
              <div
                className="flex flex-row gap-2 items-center"
                id="lampa-airdrop-point-frame"
              >
                <img src="../../assets/images/line.svg" alt="line" />
                Exclusive Brand Image for the creation of <br id="br-tag" />
                toys, clothing, etc.
              </div>
            </li>
          </ul>
        </div>
        <div className="flex flex-col md:w-1/2">
          <ul>
            <li
              className="flex flex-row items-center gap-3 py-2"
              id="lampa-airdrop-text"
            >
              <img src="../../assets/images/pyramid-6.svg" alt="pyramid" />
              <div
                className="flex flex-row gap-2 items-center"
                id="lampa-airdrop-point-frame"
              >
                <img src="../../assets/images/line.svg" alt="line" />
                Exclusive Discounts in Lampapuy <br id="br-tag" />
                and its Partner Projects
              </div>
            </li>
            <li
              className="flex flex-row items-center gap-3 py-2"
              id="lampa-airdrop-text"
            >
              <img src="../../assets/images/pyramid-6.svg" alt="pyramid" />
              <div
                className="flex flex-row gap-2 items-center"
                id="lampa-airdrop-point-frame"
              >
                <img src="../../assets/images/line.svg" alt="line" />
                Exclusive Airdrop of $LPP Coin <br id="br-tag" />
                for Lampa Holders
              </div>
            </li>
            <li
              className="flex flex-row items-center gap-3 py-2"
              id="lampa-airdrop-text"
            >
              <img src="../../assets/images/pyramid-6.svg" alt="pyramid" />
              <div
                className="flex flex-row gap-2 items-center"
                id="lampa-airdrop-point-frame"
              >
                <img src="../../assets/images/line.svg" alt="line" />
                Early Access to Lampapuy Online & <br id="br-tag" />
                Offline Events
              </div>
            </li>
            <li
              className="flex flex-row items-center gap-3 py-2"
              id="lampa-airdrop-text"
            >
              <img src="../../assets/images/pyramid-6.svg" alt="pyramid" />
              <div
                className="flex flex-row gap-2 items-center"
                id="lampa-airdrop-point-frame"
              >
                <img src="../../assets/images/line.svg" alt="line" />
                Shared Commercial Rights to NFT
              </div>
            </li>
            <li
              className="flex flex-row items-center gap-3 py-2"
              id="lampa-airdrop-text"
            >
              <img src="../../assets/images/pyramid-6.svg" alt="pyramid" />
              <div
                className="flex flex-row gap-2 items-center"
                id="lampa-airdrop-point-frame"
              >
                <img src="../../assets/images/line.svg" alt="line" />
                Usage of Profile Picture (PFP) as an Avatar in{" "}
                <br id="br-tag" />
                Socials
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
