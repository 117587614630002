import React, { Component } from "react";
import PropTypes from "prop-types";
import Slide from "./Slide";
import NavSlide from "./NavSlide";
import pyramid from "../../../../nfts/pyramid-11.svg";
import "../../../../css/LampaNft.css";

class VerticalCarousel extends React.Component {
  state = {
    index: 0,
    goToSlide: null,
    prevPropsGoToSlide: 0,
    newSlide: false,
  };

  componentDidMount = () => {
    document.addEventListener("keydown", (event) => {
      if (event.isComposing || event.keyCode === 229) {
        return;
      }
      if (event.keyCode === 38) {
        this.moveSlide(-1);
      }
      if (event.keyCode === 40) {
        this.moveSlide(1);
      }
    });
  };

  static propTypes = {
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any,
        content: PropTypes.object,
      })
    ).isRequired,
    goToSlide: PropTypes.number,
    showNavigation: PropTypes.bool,
    offsetRadius: PropTypes.number,
    animationConfig: PropTypes.object,
  };

  static defaultProps = {
    offsetRadius: 2,
    animationConfig: { tension: 120, friction: 14 },
  };

  modBySlidesLength = (index) => {
    const { slides } = this.props;
    return ((index % slides.length) + slides.length) % slides.length;
  };

  moveSlide = (direction) => {
    this.setState({
      index: this.modBySlidesLength(this.state.index + direction),
      goToSlide: null,
    });
  };

  clampOffsetRadius(offsetRadius) {
    const { slides } = this.props;
    const upperBound = Math.floor((slides.length - 1) / 2);

    if (offsetRadius < 0) {
      return 0;
    }
    if (offsetRadius > upperBound) {
      return upperBound;
    }

    return offsetRadius;
  }

  getPresentableSlides() {
    const { slides } = this.props;
    const { index } = this.state;
    let { offsetRadius } = this.props;
    offsetRadius = this.clampOffsetRadius(offsetRadius);
    const presentableSlides = new Array();

    for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {
      presentableSlides.push(slides[this.modBySlidesLength(index + i)]);
    }

    return presentableSlides;
  }

  gotoLabel = (label) => {
    const { slides } = this.props;
    const distIndex = slides.findIndex((slide) => slide.label === label);

    this.setState({
      index: this.modBySlidesLength(distIndex),
      goToSlide: null,
    });
  };

  render() {
    const { animationConfig, offsetRadius } = this.props;
    let navigationButtons = null;

    navigationButtons = (
      <div className="flex flex-col self-center relative md:absolute md:right-6 cursor-pointer z-20">
        <div onClick={() => this.moveSlide(1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="98"
            viewBox="0 0 106 98"
            fill="none"
          >
            <g filter="url(#filter0_d_871_3682)">
              <path
                d="M80.1123 69L52.9297 65.8676L53.3339 21.0017C53.6061 21.0017 53.8784 21.1364 54.034 21.4059L80.8124 67.7874C81.1235 68.3263 80.7346 69 80.1123 69Z"
                fill="#AB5706"
              />
              <path
                d="M25.8554 67.7874L52.6338 21.4059C52.7894 21.1364 53.0616 21.0017 53.3339 21.0017L52.9297 65.8676L26.5555 69C25.9332 69 25.5443 68.3263 25.8554 67.7874Z"
                fill="url(#paint0_linear_871_3682)"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_871_3682"
                x="0.746094"
                y="0.00146484"
                width="105.176"
                height="97.9985"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="12.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_871_3682"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_871_3682"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear_871_3682"
                x1="46.3631"
                y1="18.5748"
                x2="52.666"
                y2="69.3584"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFEA7F" />
                <stop offset="1" stop-color="#AB5706" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div onClick={() => this.moveSlide(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="107"
            height="98"
            viewBox="0 0 107 98"
            fill="none"
          >
            <g filter="url(#filter0_d_871_3683)">
              <path
                d="M26.5678 21L54.1563 24.1324L53.7461 68.9983C53.4698 68.9983 53.1934 68.8636 53.0356 68.5941L25.8572 22.2126C25.5414 21.6737 25.9362 21 26.5678 21Z"
                fill="#AB5706"
              />
              <path
                d="M81.6349 22.2126L54.4566 68.5941C54.2987 68.8636 54.0224 68.9983 53.7461 68.9983L54.1563 24.1324L80.9244 21C81.556 21 81.9507 21.6737 81.6349 22.2126Z"
                fill="url(#paint0_linear_871_3683)"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_871_3683"
                x="0.746094"
                y="0"
                width="106"
                height="97.9985"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="12.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_871_3683"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_871_3683"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear_871_3683"
                x1="60.821"
                y1="71.4252"
                x2="54.6081"
                y2="20.6191"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFEA7F" />
                <stop offset="1" stop-color="#AB5706" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div
          style={{
            alignSelf: "center",
            position: "absolute",
            left: "17vw",
          }}
          className="invisible md:visible"
        >
          <img src={pyramid} alt="hhh" />
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            width: "100%",
            height: "20vw",
          }}
          className="invisible md:visible"
        >
          {this.getPresentableSlides().map((slide, presentableIndex) => (
            <NavSlide
              key={slide.key}
              content={slide.content}
              moveSlide={this.moveSlide}
              offsetRadius={this.clampOffsetRadius(offsetRadius)}
              index={presentableIndex}
              animationConfig={animationConfig}
            />
          ))}
        </div>
        <div
          style={{
            alignSelf: "center",
          }}
          className="invisible md:visible"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="3"
            height="497"
            viewBox="0 0 3 497"
            fill="none"
          >
            <path
              d="M0 0L3 252.16L0 497V0Z"
              fill="url(#paint0_linear_708_5869)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_708_5869"
                x1="2.94444"
                y1="1.62684"
                x2="2.94444"
                y2="502.694"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#AB5706" stop-opacity="0" />
                <stop offset="0.480189" stop-color="#AB5706" />
                <stop offset="1" stop-color="#AB5706" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "40rem",
            alignSelf: "center",
          }}
          className="relative"
        >
          {this.getPresentableSlides().map((slide, presentableIndex) => (
            <Slide
              key={slide.key}
              content={slide.content}
              image={slide.image}
              moveSlide={this.moveSlide}
              offsetRadius={this.clampOffsetRadius(offsetRadius)}
              index={presentableIndex}
              animationConfig={animationConfig}
            />
          ))}
        </div>
        {navigationButtons}
      </React.Fragment>
    );
  }
}

export default VerticalCarousel;
