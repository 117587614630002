import React from "react";
import "../../css/Announcement.css";

export default function Announcement() {
  return (
    <div class="announcement md:mb-12 md:p-8">
      <div class="rectangle-10"></div>
      <div class="announcement2" id="annoncement-text">
        ANNOUNCEMENT
      </div>
      <div class="roadmap">
        <div class="we-are-excited-to-announce-the-release-of-100-000-lampapuy-pfp-profile-picture-nf-ts-to-lpp-stakers-and-community-get-ready-to-explore-and-own-these-unique-and-limited-edition-digital-artworks-lampapuy-dao-will-oversee-all-operations-through-community-voting-ensuring-that-our-community-members-have-a-direct-and-influential-role-in-decision-making-processes-let-s-make-lampapuy-legendary">
          <span>
            <span
              class="we-are-excited-to-announce-the-release-of-100-000-lampapuy-pfp-profile-picture-nf-ts-to-lpp-stakers-and-community-get-ready-to-explore-and-own-these-unique-and-limited-edition-digital-artworks-lampapuy-dao-will-oversee-all-operations-through-community-voting-ensuring-that-our-community-members-have-a-direct-and-influential-role-in-decision-making-processes-let-s-make-lampapuy-legendary-span"
              id="annoncement-text"
            >
              We are excited to announce the release of 10,000 Lampapuy PFP
              (Profile Picture) NFTs to $LPP stakers and community. Get ready to
              explore and own these unique and limited-edition digital artworks!
              <br />
              <br />
              Lampapuy DAO will oversee all operations through community voting,
              ensuring that our community members have a direct and influential
              role in decision-making processes.
              <br />
              <br />
            </span>
            <div className="md:pb-20">
              <span
                class="we-are-excited-to-announce-the-release-of-100-000-lampapuy-pfp-profile-picture-nf-ts-to-lpp-stakers-and-community-get-ready-to-explore-and-own-these-unique-and-limited-edition-digital-artworks-lampapuy-dao-will-oversee-all-operations-through-community-voting-ensuring-that-our-community-members-have-a-direct-and-influential-role-in-decision-making-processes-let-s-make-lampapuy-legendary-span2"
                id="annoncement-text"
              >
                Let&#039;s make Lampapuy legendary!
              </span>
              <span class="we-are-excited-to-announce-the-release-of-100-000-lampapuy-pfp-profile-picture-nf-ts-to-lpp-stakers-and-community-get-ready-to-explore-and-own-these-unique-and-limited-edition-digital-artworks-lampapuy-dao-will-oversee-all-operations-through-community-voting-ensuring-that-our-community-members-have-a-direct-and-influential-role-in-decision-making-processes-let-s-make-lampapuy-legendary-span3"></span>
              <span
                class="we-are-excited-to-announce-the-release-of-100-000-lampapuy-pfp-profile-picture-nf-ts-to-lpp-stakers-and-community-get-ready-to-explore-and-own-these-unique-and-limited-edition-digital-artworks-lampapuy-dao-will-oversee-all-operations-through-community-voting-ensuring-that-our-community-members-have-a-direct-and-influential-role-in-decision-making-processes-let-s-make-lampapuy-legendary-span4"
                id="annoncement-text"
              >
                🚀💡
              </span>
            </div>
          </span>
        </div>
      </div>
      <div class="group-3">
        <div class="coin">
          <div class="ellipse-1 hidden md:visible"></div>
          <div class="rectangle-1 hidden"></div>
          <img
            alt="coin"
            class="image-6"
            src="../../assets/images/image-3.png"
          />
        </div>
        <div class="coin2">
          <div class="ellipse-12 hidden md:visible"></div>
          <div class="rectangle-12 hidden md:visible"></div>
          <img
            alt="coin"
            class="image-62"
            src="../../assets/images/image-4.png"
          />
        </div>
      </div>
    </div>
  );
}
