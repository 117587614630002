import React from "react";

import ArtContent from "../components/ArtContent";

export default function LPPArt() {
  return (
    <div>
      <ArtContent />
    </div>
  );
}
