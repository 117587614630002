import React, { useState, useEffect } from "react";
import ComingSoon from "./subComponents.js/ComingSoon";
import MarketplaceHero from "./subComponents.js/MarketplaceHero";
import NFTLaunch from "./subComponents.js/NFTLaunch";

export default function NftMarketplace() {
  const [comingSoon, setComingSoon] = useState(false); // Initialize as boolean

  const handleComingSoon = () => {
    setComingSoon(!comingSoon);
  };

  return (
    <div style={{ backgroundColor: "#000" }}>
      {comingSoon && (
        <div
          onClick={handleComingSoon}
          className="w-full h-full fixed top-0 left-0 flex items-center justify-center font-inter z-40 modal"
        >
          <ComingSoon />
        </div>
      )}
      <MarketplaceHero handleComingSoon={handleComingSoon} />
      <NFTLaunch handleComingSoon={handleComingSoon} />
    </div>
  );
}
