import React from "react";
import "../../css/MobileRoadmap.css";

export default function MobileRoadmap() {
  return (
    <div className="mobile-roadmap visible md:hidden">
      <div className="quarter-card">
        <div className="heading">
          <div className="q-1-2024">Q1 2024</div>
          <img
            className="image-10"
            alt="penguine"
            src="../../assets/images/image-1.png"
          />
          <svg
            className="ellipse-4"
            width="358"
            height="110"
            viewBox="0 0 358 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M358 109.148H106.037C115.156 100.645 121.548 89.6281 124.406 77.4914C127.264 65.3548 126.459 52.6433 122.092 40.9643C117.725 29.2854 109.994 19.1635 99.8745 11.8788C89.7554 4.59404 77.7033 0.473563 65.2423 0.038416C52.7814 -0.396731 40.4712 2.873 29.8685 9.43412C19.2659 15.9953 10.8469 25.5531 5.67628 36.899C0.505651 48.2449 -1.18441 60.8693 0.819818 73.1757C2.82404 85.4821 9.25236 100.029 17.7559 109.148H0.819818"
              stroke="url(#paint0_linear_388_1778)"
              stroke-width="0.147514"
            />
            <defs>
              <linearGradient
                id="paint0_linear_388_1778"
                x1="59.4799"
                y1="6.31517"
                x2="85.8172"
                y2="151.229"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFEA7F" />
                <stop offset="1" stop-color="#AB5706" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="q-1-goals">
          <ul className="list-disc">
            <li>Whitepaper & Roadmap Release</li>
            <li> Website, Social Media Launch</li>
            <li>Partnership & Collaboration</li>
            <li>Marketing Campaign Launch</li>
            <li>Community Incentives Unveiled</li>
            <li>Public Sale / IDO</li>
          </ul>
        </div>
      </div>
      <div className="quarter-card2">
        <div className="heading">
          <div className="q-2-2024">Q2 2024</div>
          <svg
            className="ellipse-42"
            width="358"
            height="110"
            viewBox="0 0 358 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M358 109.296H120.537C129.656 100.793 136.048 89.7763 138.906 77.6396C141.764 65.503 140.959 52.7915 136.592 41.1125C132.225 29.4336 124.494 19.3117 114.375 12.027C104.255 4.74223 92.2033 0.621756 79.7423 0.186609C67.2814 -0.248538 54.9712 3.02119 44.3685 9.58232C33.7659 16.1434 25.3469 25.7013 20.1763 37.0472C15.0057 48.3931 13.3156 61.0175 15.3198 73.3239C17.324 85.6303 24.9325 100.178 33.4361 109.296H0"
              stroke="url(#paint0_linear_388_2125)"
              stroke-width="0.147514"
            />
            <defs>
              <linearGradient
                id="paint0_linear_388_2125"
                x1="59.4799"
                y1="6.46336"
                x2="85.8172"
                y2="151.377"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFEA7F" />
                <stop offset="1" stop-color="#AB5706" />
              </linearGradient>
            </defs>
          </svg>
          <img
            className="image-11"
            alt="penguine"
            src="../../assets/images/image-2.png"
          />
        </div>
        <div className="q-2-goals">
          <ul className="list-disc">
            <li>TGE & Listing</li>
            <li>Stake $LPP: share NFT & Domain sale profits</li>
            <li>.LPP Domain Launch with Bictory.domains</li>
            <li>Lampapuy PFPs Kickoff with Bictory.io</li>
            <li>PFP NFTs Sale</li>
            <li>$LPP Integration with Other Projects</li>
            <li>Lampapuy DAO Operations Commence</li>
            <li>Community-Initiated Projects Launch</li>
          </ul>
        </div>
      </div>
      <div className="quarter-card3">
        <div className="heading">
          <div className="q-3-2024">Q3 2024</div>
          <svg
            className="ellipse-43"
            width="358"
            height="110"
            viewBox="0 0 358 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M358 109.445H120.537C129.656 100.941 136.048 89.9245 138.906 77.7879C141.764 65.6512 140.959 52.9397 136.592 41.2608C132.225 29.5818 124.494 19.46 114.375 12.1752C104.255 4.89049 92.2033 0.770011 79.7423 0.334864C67.2814 -0.100283 54.9712 3.16945 44.3685 9.73057C33.7659 16.2917 25.3469 25.8495 20.1763 37.1954C15.0057 48.5413 13.3156 61.1657 15.3198 73.4721C17.324 85.7786 24.9325 100.326 33.4361 109.445H0"
              stroke="url(#paint0_linear_388_2135)"
              stroke-width="0.147514"
            />
            <defs>
              <linearGradient
                id="paint0_linear_388_2135"
                x1="59.4799"
                y1="6.61162"
                x2="85.8172"
                y2="151.525"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFEA7F" />
                <stop offset="1" stop-color="#AB5706" />
              </linearGradient>
            </defs>
          </svg>
          <img
            className="image-112"
            alt="penguine"
            src="../../assets/images/image-2.png"
          />
        </div>
        <div className="q-3-goals">
          <ul className="list-disc">
            <li>Staking & Governance Unveiled</li>
            <li>NFT ticketing & Crowdvoting Launch</li>
            <li>Crowdfunding Launch</li>
            <li>NFT Airdrop for Loyal Lampas</li>
            <li>Promotional Fund Launch</li>
            <li>100K Holders Milestone: $1M Giveaways</li>
          </ul>
        </div>
      </div>
      <div className="quarter-card4">
        <div className="heading">
          <div className="q-3-20242">Q4 2024</div>
          <svg
            className="ellipse-44"
            width="358"
            height="110"
            viewBox="0 0 358 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M358 109.593H120.537C129.656 101.089 136.048 90.0728 138.906 77.9361C141.764 65.7995 140.959 53.088 136.592 41.409C132.225 29.7301 124.494 19.6082 114.375 12.3235C104.255 5.03874 92.2033 0.918265 79.7423 0.483118C67.2814 0.0479711 54.9712 3.3177 44.3685 9.87883C33.7659 16.44 25.3469 25.9978 20.1763 37.3437C15.0057 48.6896 13.3156 61.314 15.3198 73.6204C17.324 85.9268 24.9325 100.474 33.4361 109.593H0"
              stroke="url(#paint0_linear_388_2141)"
              stroke-width="0.147514"
            />
            <defs>
              <linearGradient
                id="paint0_linear_388_2141"
                x1="59.4799"
                y1="6.75987"
                x2="85.8172"
                y2="151.674"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFEA7F" />
                <stop offset="1" stop-color="#AB5706" />
              </linearGradient>
            </defs>
          </svg>
          <img
            className="image-113"
            alt="penguine"
            src="../../assets/images/image-2.png"
          />
        </div>
        <div className="q-4-goals">
          <ul className="list-disc">
            <li>Lampapuy Online & Offline Event</li>
            <li>Partnership Expansion with Organizers & Creators</li>
            <li>Advanced NFT & DeFi Feature Integration</li>
            <li>Global Outreach & Adoption Initiatives</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
