import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import DropdownMenu from "./subComponents.js/DropdownMenu";
import "../App.css";
import MobileNav from "./subComponents.js/MobileNav";
import MobileDropdown from "./subComponents.js/MobileDropdown";

const Navbar = ({ changeColor }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isMobileNavVisible, setMobileNavVisible] = useState(false);
  const [isMobileDropdownVisible, setMobileDropdownVisible] = useState(false);

  const { pathname } = useLocation();
  useEffect(() => {
    setMobileDropdownVisible(false);
  }, [pathname]);

  const openDropdown = () => {
    setMobileDropdownVisible(!isMobileDropdownVisible);
  };

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const openMobileNav = () => {
    setMobileNavVisible(!isMobileNavVisible);
    setMobileDropdownVisible(false);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  return (
    <nav className="bg-black sticky bg-opacity-20 md:top-0 z-40 ">
      <div className="max-w-screen-xl mx-auto flex items-center justify-between py-4 md:px-2 px-4">
        <div className="md:w-1/6 flex items-center justify-between md:justify-start w-full md:px-6">
          <NavLink to="/">
            <img
              src="./assets/images/lampapuy-logo.svg"
              className="h-16"
              alt="Logo"
            />
          </NavLink>

          <div
            className="visible md:hidden"
            id="nav-svg"
            onClick={openMobileNav}
          >
            {isMobileNavVisible ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.9912 6L5.99121 18M5.99121 6L17.9912 18"
                  stroke="url(#paint0_linear_857_1574)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_857_1574"
                    x1="7.98495"
                    y1="6.6943"
                    x2="15.2237"
                    y2="18.8376"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FFEA7F" />
                    <stop offset="1" stop-color="#AB5706" />
                  </linearGradient>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3 12H21M3 6H21M9 18H21"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          {isMobileNavVisible && <MobileNav openDropdown={openDropdown} />}
          {isMobileDropdownVisible && <MobileDropdown />}
        </div>
        <div className="hidden md:flex md:items-center md:justify-center">
          <ul
            className="font-medium text-lg flex flex-col md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0"
            id="nav-links"
          >
            <li>
              <NavLink to="/" className="px-4 rounded-md " id="nav-link">
                Homepage
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" className="px-4 rounded-md " id="nav-link">
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/LampapuyNFTs"
                className="px-4 rounded-md "
                id="nav-link"
              >
                NFT Marketplace
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/10KLampaNFTs"
                className="px-4 rounded-md "
                id="nav-link"
              >
                10K Lampa NFTs
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://bictory.domains/SolNames"
                className="px-4 rounded-md "
                id="nav-link"
                target="_blank"
              >
                LPP Domains
              </NavLink>
            </li>
            <li>
              <NavLink to="/lppart" className="px-4 rounded-md " id="nav-link">
                $LPP Art
              </NavLink>
            </li>
            <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <div
                className=" rounded-md hover:cursor-pointer  flex flex-col items-center gap-2"
                id="nav-link"
              >
                <div className="flex flex-row hover:active items-center">
                  <span>Resources </span>

                  {isDropdownVisible ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M18 15.9854L12 9.98535L6 15.9854"
                        stroke="url(#paint0_linear_561_1629)"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_561_1629"
                          x1="7.99374"
                          y1="10.3325"
                          x2="10.2464"
                          y2="17.8903"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FFEA7F" />
                          <stop offset="1" stop-color="#AB5706" />
                        </linearGradient>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      className=""
                    >
                      <path
                        d="M6 9.98535L12 15.9854L18 9.98535"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
                {isDropdownVisible && <DropdownMenu />}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
