import React, { Component } from "react";
import VerticalCarousel from "./VerticalCarousel";
import uuidv4 from "uuid";
import { config } from "react-spring";
import nft1 from "../../../../nfts/nft1.png";
import nft2 from "../../../../nfts/nft2.png";
import nft3 from "../../../../nfts/nft3.png";
import nft4 from "../../../../nfts/nft4.png";
import nft5 from "../../../../nfts/nft5.png";
import nft6 from "../../../../nfts/nft6.png";
import nft7 from "../../../../nfts/nft7.png";
import nft8 from "../../../../nfts/nft8.png";
import nft9 from "../../../../nfts/nft9.png";

let slides = [
  {
    key: 1,
    label: "slide 1",
    content: "Penguin",
    image: nft1,
  },
  {
    key: 2,
    label: "slide 2",
    content: "Rabbit",
    image: nft2,
  },
  {
    key: 3,
    label: "slide 3",
    content: "Bull",
    image: nft3,
  },
  {
    key: 4,
    label: "slide 4",
    content: "Bear",
    image: nft4,
  },
  {
    key: 5,
    label: "slide 5",
    content: "Shark",
    image: nft5,
  },
  {
    key: 6,
    label: "slide 6",
    content: "Turtle",
    image: nft6,
  },
  {
    key: 7,
    label: "slide 7",
    content: "Fox",
    image: nft7,
  },
  {
    key: 8,
    label: "slide 8",
    content: "Tiger",
    image: nft8,
  },
  {
    key: 9,
    lable: "",
    content: "Dodo",
    image: nft9,
  },
];

export default class Example extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: false,
    config: config.gentle,
  };

  render() {
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
          margin: "0",
        }}
        className="flex-row md:flex-row gap-28 px-2 pt-5 md:p-0 md:gap-0"
      >
        <VerticalCarousel
          slides={slides}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
        />
      </div>
    );
  }
}
